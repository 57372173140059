import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    acoes: [],
    acoesEdit: [],
    opcoes: [],
    error: null,
    loading: false,
    add: false
}

const deleteAcoesSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteAcoesFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const addAcoesSuccess = (state, action) => {
    const newState = {
        acoes: action.acoes,
        acoesEdit: action.acoes, 
        add: true
    }
    return updateObject(state,newState);
}

const addAcoesFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const setOpcoesSuccess = (state, action) => {
    const newState = {
        opcoes: action.opcoes, 
        acoesEdit: action.acoesEdit, 
        loading: false
    };
    return updateObject(state, newState)
}
const clearAcoes = (state, action) => {
    const exit = {
        acoes: [],
        acoesEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_ACOES_START:
            return updateObject(state, {loading: true});

        case actionTypes.DELETE_ACOES_SUCCESS:
            return deleteAcoesSuccess(state, action); 

        case actionTypes.DELETE_ACOES_FAIL:
            return deleteAcoesFail(state, action);

        case actionTypes.ADD_ACOES_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_ACOES_SUCCESS:
            return addAcoesSuccess(state, action); 

        case actionTypes.ADD_ACOES_FAIL:
            return addAcoesFail(state, action);

        case actionTypes.SET_ACOES_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_ACOES_SUCCESS: 
            return updateObject(state, {acoes: action.acoes, loading: false});

        case actionTypes.SET_ACOES_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_ACOES: 
            return clearAcoes(state, action);
        
        case actionTypes.SET_OPCOES_START: 
            return updateObject(state, {loading: true});

        case actionTypes.SET_OPCOES_SUCCESS: 
            return setOpcoesSuccess(state, action);

        case actionTypes.SET_OPCOES_FAIL: 
            return updateObject(state, {loading: false});    

        default:
            return state;
    }
}

export default reducer;