import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    categorias: [],
    subcategorias: [],
    categoriaEdit: [],
    error: null,
    loading: false,
    add: false
}

const deleteCategoriaSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteCategoriaFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: 'Erro inesperado, por favor entre em contato com o administrador do sistema.'              
    });
}

const addCategoriaSuccess = (state, action) => {
    const newState = {
        categorias: action.categorias,
        categoriaEdit: action.categoriaEdit, 
        add: true
    }
    return updateObject(state,newState);
}

const addCategoriaFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: 'Erro inesperado, por favor entre em contato com o administrador do sistema.'              
    });
}

const clearCategoria = (state, action) => {
    const exit = {
        subcategorias: [],
        categorias: [],
        categoriaEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_CATEGORIA_START:
            return updateObject(state, {loading: true});

        case actionTypes.DELETE_CATEGORIA_SUCCESS:
            return deleteCategoriaSuccess(state, action); 

        case actionTypes.DELETE_CATEGORIA_FAIL:
            return deleteCategoriaFail(state, action);

        case actionTypes.ADD_CATEGORIA_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_CATEGORIA_SUCCESS:
            return addCategoriaSuccess(state, action); 

        case actionTypes.ADD_CATEGORIA_FAIL:
            return addCategoriaFail(state, action);

        case actionTypes.SET_CATEGORIA_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_CATEGORIA_SUCCESS: 
            return updateObject(state, {categoriaEdit: action.categoriaEdit, loading: false});

        case actionTypes.SET_CATEGORIA_FAIL: 
            return updateObject(state, {loading: false});

        
        case actionTypes.SET_CATEGORIAS_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_CATEGORIAS_SUCCESS: 
            return updateObject(state, {categorias: action.categorias, loading: false});

        case actionTypes.SET_CATEGORIAS_FAIL: 
            return updateObject(state, {loading: false});
            
        case actionTypes.SET_SUB_CATEGORIAS_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_SUB_CATEGORIAS_SUCCESS: 
            return updateObject(state, {subcategorias: action.subcategorias, loading: false});

        case actionTypes.SET_SUB_CATEGORIAS_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_CATEGORIAS: 
            return clearCategoria(state, action);   

        default:
            return state;
    }
}

export default reducer;