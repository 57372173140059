import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    atendimentoId: null,
    atendimento: null,
    atendimentos: null,
    success: null,
    error: null,
    loading: false,
};
//SET STATUS
const setAtendimentoStatusStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null
    }
    return updateObject(state, updateState);
}

const setAtendimentoStatusSuccess = (state, action) => {
    const updateState = {
        loading: false,
        error: null
    }
    return updateObject(state, updateState);
}
//GET
const setAtendimentoStart = (state, action) => {
    const updateState = {
        atendimentoId: null,
        atendimento: null,
        loading: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const setAtendimentoSuccess = (state, action) => {
    const updateState = {
        atendimentoId: action.atendimentoId,
        atendimento: action.atendimento,
        loading: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}

const atendimentoClean = (state, action) => {
    const updateState = {
        atendimentoId: null,
        atendimento: null,
        atendimentos: null,
        success: null,
        error: null,
        loading: false,
    }
    return updateObject(state, updateState);
}

const setAtendimentoFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error.code){
        case 'auth/user-not-found':
            errorMsg = 'O E-mail não cadastrado';
            break;
        default:
            errorMsg = 'Error para criar uma conta por favor entrar em contato com assistência.'
    }
    const updateState = {
        loading: false,
        error: errorMsg,
        success: null
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //GET
        case actionTypes.SET_ATENDIMENTO_START:
            return setAtendimentoStart(state, action);
        case actionTypes.SET_ATENDIMENTO_SUCCESS:
            return setAtendimentoSuccess(state, action);
        case actionTypes.SET_ATENDIMENTO_FAIL:
            return setAtendimentoFail(state, action);
        //GET ALL
        case actionTypes.SET_ATENDIMENTOS_START: 
            return updateObject(state, {loading: true, add: false});
        case actionTypes.SET_ATENDIMENTOS_SUCCESS: 
            return updateObject(state, {atendimentos: action.atendimentos, loading: false});
        //MUDAR STATUS
        case actionTypes.SET_ATENDIMENTO_STATUS_START:
            return setAtendimentoStatusStart(state, action);
        case actionTypes.SET_ATENDIMENTO_STATUS_SUCCESS:
            return setAtendimentoStatusSuccess(state, action);

        //ANEXAR COMPROVANTES
        case actionTypes.ANEXAR_ATENDIMENTO_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.ANEXAR_ATENDIMENTO_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        //DETELE COMPROVANTES
        case actionTypes.DELETE_ANEXO_ATENDIMENTO_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.DELETE_ANEXO_ATENDIMENTO_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        case actionTypes.ATENDIMENTO_CLEAN:
            return atendimentoClean(state, action);
        default:
            return state
    }
}

export default reducer;