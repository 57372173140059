import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeEstados = () => {};
// GET ALL
export const setEstadosStart = () => {
    return {
        type: actionTypes.SET_ESTADOS_START
    }
}

export const getEstados = (header = null, options = null) => {
    return dispatch =>{
        dispatch(setEstadosStart());
        
        unsubscribeEstados = FireBase.db.collection('estados')
            .orderBy('nome', 'asc')
            //.where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setEstadosSuccess(docAux));
            });
    }  
}

export const setEstadosSuccess = (estados) => {
    return {
        type: actionTypes.SET_ESTADOS_SUCCESS,
        estados: estados
    }
}

export const setEstadosFail = (error) => {
    return {
        type: actionTypes.SET_ESTADOS_FAIL,
        error: error
    }
}
// EXIT UNIDADE
export const exitEstados = () => {
    return dispatch => {
        unsubscribeEstados();
        dispatch(exitEstadadoSuccess());
    }
}

export const exitEstadadoSuccess = () => {
    return {
        type: actionTypes.EXIT_ESTADOS
    }
}