import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
/* IMPORT REDUX */
import thunk from 'redux-thunk';

import notifications from '../store/reducers/notification';

import auth from '../store/reducers/auth';
import register from '../store/reducers/register';
import forgotpass from '../store/reducers/forgotpass';

import acoes from '../store/reducers/acoes';
import funcoes from '../store/reducers/funcoes';

import comprar from '../store/reducers/comprar';
import atendimento from '../store/reducers/atendimento';
import vcard from '../store/reducers/vcard';
import chat from '../store/reducers/chat';
import user from '../store/reducers/user';
import categorias from '../store/reducers/categorias';
import estadoscidades from '../store/reducers/estadoscidades';
import contatos from '../store/reducers/contatos';

import unidades from '../store/reducers/unidades';
import fornecedores from '../store/reducers/fornecedores';
/* CONFIG REDUX */

let composeEnhacers = process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

let rootReducer = combineReducers({
    notificationReducer: notifications,
    authReducer: auth,
    registerReducer: register,
    forgotpassReducer: forgotpass,
    acoesReducer: acoes,
    funcoesReducer: funcoes,
    comprarReducer: comprar,
    atendimentoReducer: atendimento,
    vcardReducer: vcard,
    chatReducer: chat,
    unidadesReducer: unidades,
    userReducer: user,
    categoriasReducer: categorias,
    fornecedoresReducer: fornecedores,
    estadoscidadesReducer: estadoscidades,
    contatosReducer: contatos
});

let persistConfig = {
    key: 'root',
    storage,
};

let persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
    let store = createStore(persistedReducer, composeEnhacers(
        applyMiddleware(thunk)
    ));
    let persistor = persistStore(store);
    return {store, persistor}
}