import * as actionTypes from './actionTypes';

export const openNotification = (content, classe) => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(openSuccess(content, classe));
            setTimeout(()=>{
                dispatch(closeNotification());
            }, 3000);
        }, 500);
    }
}

export const openSuccess = (content, classe) => {
    return {
        type: actionTypes.NOTIFICATION_OPENSUCCESS,
        content: content, 
        classe: classe
    }
}

export const closeNotification = () => {
    return {
        type: actionTypes.NOTIFICATION_CLOSE
    }
}