import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

export const forgotpassCleanError = () => {
    return {
        type: actionTypes.FORGOTPASS_CLEANERROR
    }
};

export const forgotpassCleanSuccess = () => {
    return {
        type: actionTypes.FORGOTPASS_CLEANSUCCESS
    }
};

export const forgotpassStart = () => {
    return {
        type: actionTypes.FORGOTPASS_START
    }
};

export const forgotpassSuccess = (uid) => {
    return {
        type: actionTypes.FORGOTPASS_SUCCESS,
        uid: uid
    }
};

export const forgotpassFail = (error) => {
    return {
        type: actionTypes.FORGOTPASS_FAIL,
        error: error
    }
};

export const forgotpass = (email) => {
    return dispatch => {
        dispatch(forgotpassStart());
        
        FireBase.auth.doPasswordReset(email)
            .then(res=>{
                dispatch(forgotpassSuccess());
                dispatch(forgotpassCleanSuccess());
            })
            .catch(err=>{
                dispatch(forgotpassFail(err));
            });
    }
};