import 'firebase/firestore';

class firebaseDB {
    constructor(dbInit) {
        this.DB = dbInit.firestore();
        this.FieldValue = dbInit.firestore.FieldValue;
    };

    collection = (c) => this.DB.collection(c);
    enablePersistence = () => this.DB.enablePersistence();
    serverTimestamp = () => this.FieldValue.serverTimestamp();
    
}
  
export default firebaseDB;