import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    register: false,
    userId: null,
    userName: null,
    success: null,
    error: null,
    loading: false,
};

const registerStart = (state, action) => {
    const updateState = {
        userName: null,
        success: null,
        register: true,
        loading: true,
        error: null
    }
    return updateObject(state, updateState);
}

const registerSuccess = (state, action) => {
    const updateState = {
        userId: action.userId,
        userName: action.userName,
        register: false,
        error: null,
        loading: false,
        success: true
    }
    return updateObject(state, updateState);
}

const registerCleanError = (state, action) => {
    const updateState = {
        error: null
    }
    return updateObject(state, updateState);
}

const registerCleanSuccess = (state, action) => {
    const updateState = {
        success: null
    }
    return updateObject(state, updateState);
}

const registerClean = (state, action) => {
    const updateState = {
        register: false,
        userId: null,
        userName: null,
        success: null,
        error: null,
        loading: false,
    }
    return updateObject(state, updateState);
}

const registerFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error.code){
        case 'auth/email-already-in-use':
            errorMsg = 'O E-mail já esta em uso.';
            break;
        default:
            errorMsg = 'Error no login por favor entrar em contato com assistência. '+action.error.code
    }
    const updateState = {
        register: false,
        loading: false,
        error: errorMsg
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case actionTypes.USER_SUCCESS:
        //     return userSuccess(state, action);
        // case actionTypes.USER_FAIL:
        //     return userFail(state, action);
        // case actionTypes.USER_START:
        //     return userStart(state, action);
        case actionTypes.REGISTER_START:
            return registerStart(state, action);
        case actionTypes.REGISTER_SUCCESS:
            return registerSuccess(state, action);
        case actionTypes.REGISTER_FAIL:
            return registerFail(state, action);
        case actionTypes.REGISTER_CLEANSUCCESS:
            return registerCleanSuccess(state, action);
        case actionTypes.REGISTER_CLEANERROR:
            return registerCleanError(state, action);
        case actionTypes.REGISTER_CLEAN:
            return registerClean(state, action);
        default:
            return state
    }
}

export default reducer;