import 'firebase/storage';

class firebaseDB {
    constructor(storageInit) {
        this.STORAGE = storageInit.storage();
        //this.FieldValue = storageInit.firestore.FieldValue;
    };

    ref = (c) => this.STORAGE.ref(c);
    // serverTimestamp = () => this.FieldValue.serverTimestamp();
    
}
  
export default firebaseDB;