import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    chat: null,
    chats: null,
    msgs: [],
    oldMsg: [],
    success: null,
    error: null,
    loading: false,
    donoVideo: null,
    convidadeVideo: null,
    donoData: null,
    convidadeData: null
};

//CLEAN
const chatClean = (state, action) => {
    const updateState = {
        chat: null,
        chats: null,
        msgs: [],
        oldMsg: [],
        success: null,
        error: null,
        loading: false,
        donoVideo: null,
        convidadeVideo: null,
        donoData: null,
        convidadeData: null
    }
    return updateObject(state, updateState);
}

const setChatMsgStar = (state, action) => {
    let updateState = state;
    if(!action.oldMsg){
        updateState = {
            msgs: []
        }
    }else{
        updateState = {
            oldMsg: []
        }   
    }
    return updateObject(state, updateState);
}

const setChatMsgSuccess = (state, action) => {
    let updateState = state;
    if(action.msgs){
        updateState = {
            msgs: action.msgs,
            success: true
        }
    }
    if(action.oldMsg){
        updateState = {
            oldMsg: action.oldMsg,
            success: true
        }
    }
    console.log(action)
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //ADD MSG CHAT VCARD
        case actionTypes.ADD_CHAT_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.ADD_CHAT_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        //SET CHAT
        case actionTypes.SET_CHAT_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.SET_CHAT_SUCCESS:
            return updateObject(state, {chat: action.chat, success: null, error: null,loading: false});

        //SET CHATS
        case actionTypes.SET_CHATS_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.SET_CHATS_SUCCESS:
            return updateObject(state, {chats: action.chats, success: null, error: null,loading: false});

        //SET CHAT MSG
        case actionTypes.SET_CHAT_MSG_START:
            return setChatMsgStar(state, action);
        case actionTypes.SET_CHAT_MSG_SUCCESS:
            return setChatMsgSuccess(state, action);

        //ADD CHAT VIDEO
        case actionTypes.ADD_CHAT_VIDEO_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.ADD_CHAT_VIDEO_SUCCESS:
            return updateObject(state, {...action.userVideo,...{success: true, error: null,loading: false}});

        //CLEAN CHAT VIDEO
        case actionTypes.CLEAN_CHAT_VIDEO_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.CLEAN_CHAT_VIDEO_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});
         
            
        case actionTypes.CHAT_CLEAN:
            return chatClean(state, action);
        default:
            return state
    }
}

export default reducer;