import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import Firebase from '../../../utils/firebase';

import Redirect from '../../../components/Navigation/Redirect';

class Logout extends Component {
    state = {
        logoutSuccess: false,
        userIdAux: null
    }

    componentDidMount(){
        if(this.props.userId){
            this.setState({userIdAux: this.props.userId})
        }
        this.props.logout();
    }
    
    componentDidUpdate() {
        if(this.state.userIdAux){
            Firebase.db.collection('tokennotification')
                .doc(this.state.userIdAux)
                .set({token: ''})
            console.log(this.state.userIdAux)
        }
        if(!this.props.userId){
            this.setState({logoutSuccess: true})
        }
    }

    render() {
        if(this.state.logoutSuccess){
            this.props.onOpenNotification('Logout efetuado com sucesso', ['Danger'])
            return <Redirect to="/" />;
        }else{
            return null;
        }
    }
}
const mapStatetoProps = state =>{
    return{
        userId: state.authReducer.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenNotification: (content, classe)=> dispatch(actionCreators.openNotification(content, classe)),
        logout: ()=>dispatch(actionCreators.logout()),
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(Logout);
