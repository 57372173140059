import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeFuncoes = () => {};

//DELETE
export const deleteFuncaoStart = () => {
    return {
        type: actionTypes.DELETE_FUNCAO_START
    };
}

export const deleteFuncao = (id, userId) => {
    return dispatch => {
        
        dispatch(deleteFuncaoStart());

        FireBase.db.collection('users')
            .where('funcao', '==', id)
            .where('desativado', '==', false)
            .get()
            .then(res=>{
                if(res.size > 0){
                    dispatch(deleteFuncaoFail('Existe usuário usando essa função!'));
                }else{
                    FireBase.db.collection('funcoes')
                        .doc(id)
                        .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
                        .then((response) => {
                            dispatch(deleteFuncaoSuccess());
                        })
                        .catch(error => {
                            dispatch(deleteFuncaoFail(error));
                        });
                }                
            })
            .catch(error => {
                dispatch(deleteFuncaoFail(error));
            });
    }
}

export const deleteFuncaoSuccess = () => {
    return {
        type: actionTypes.DELETE_FUNCAO_SUCCESS
    };
};

export const deleteFuncaoFail = (error) => {
    return {
        type: actionTypes.DELETE_FUNCAO_FAIL,
        error: error
    };
};

//ADICIONAR E ATUALIZAR
export const addFuncaoStart = () => {
    return {
        type: actionTypes.ADD_FUNCAO_START
    };
}

export const addFuncao = (funcao, userId, update = null) => {
    return dispatch => {
        if(!update){
            funcao = {...funcao, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            funcao = {...funcao, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        
        dispatch(addFuncaoStart());
        if(update === null){
            FireBase.db.collection('funcoes').doc(funcao.id).get()
                .then(res=>{
                    if(res.exists && res.data().desativado === false){
                        dispatch(addFuncaoFail('Já existe uma função com esse nome!'));
                    }else{
                        const id = funcao.id;
                        delete funcao.id;
                        FireBase.db.collection('funcoes')
                            .doc(id)
                            .set(funcao)
                            .then((response) => {
                                dispatch(addFuncaoSuccess());
                            })
                            .catch(error => {
                                dispatch(addFuncaoFail(error));
                            });
                    }                
                })
                .catch(error => {
                    dispatch(addFuncaoFail(error));
                });
        }else{
            const id = funcao.id;
            delete funcao.id;
            FireBase.db.collection('funcoes')
                .doc(id).update(funcao)
                .then((response) => {
                    dispatch(addFuncaoSuccess());
                })
                .catch(error => {
                    dispatch(addFuncaoFail(error));
                });
        }
    }
}

export const addFuncaoSuccess = () => {
    return {
        type: actionTypes.ADD_FUNCAO_SUCCESS,
        funcoes: [],
        funcaoEdit: [],
    };
};

export const addFuncaoFail = (error) => {
    return {
        type: actionTypes.ADD_FUNCAO_FAIL,
        error: error
    };
};
// GET ALL
export const setFuncoesStart = () => {
    return {
        type: actionTypes.SET_FUNCOES_START
    }
}

export const getFuncoes = (header = null, options = null) => {
    return dispatch =>{
        dispatch(setFuncoesStart());
        
        unsubscribeFuncoes = FireBase.db.collection('funcoes')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();
                    d.name = doc.id;
                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setFuncoesSuccess(docAux));
            });
    }  
}

export const setFuncoesSuccess = (funcoes) => {
    return {
        type: actionTypes.SET_FUNCOES_SUCCESS,
        funcoes: funcoes
    }
}

export const setFuncoesFail = (error) => {
    return {
        type: actionTypes.SET_FUNCOES_FAIL,
        error: error
    }
}

//GET
export const setFuncaoStart = () => {
    return {
        type: actionTypes.SET_FUNCAO_START
    }
}

export const getFuncao = (id) => {
    return dispatch =>{
        dispatch(setFuncaoStart());
        
        FireBase.db.collection('funcoes')
            .doc(id).get()
            .then(doc => {
                const docAux = doc.data();
                docAux.id = doc.id;
                dispatch(setFuncaoSuccess(docAux));
            })
            .catch(error=>{
                dispatch(setFuncaoFail(error));
            });
    }  
}

export const setFuncaoSuccess = (funcaoEdit) => {
    return {
        type: actionTypes.SET_FUNCAO_SUCCESS,
        funcaoEdit: funcaoEdit
    }
}

export const setFuncaoFail = (error) => {
    return {
        type: actionTypes.SET_FUNCAO_FAIL,
        error: error
    }
}
// EXIT FUNCOES
export const exitFuncoes = () => {
    return dispatch => {
        unsubscribeFuncoes();
        dispatch(exitFuncoesSuccess());
    }
}

export const exitFuncoesSuccess = () => {
    return {
        type: actionTypes.EXIT_FUNCOES
    }
}