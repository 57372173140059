import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

export const comprarClean = () => {
    return {
        type: actionTypes.COMPRAR_CLEAN
    }
};

export const comprarAtendimentoStart = () => {
    return {
        type: actionTypes.COMPRAR_ATENDIMENTO_START
    }
};

export const comprarPagamentoStartSuccess = () => {
    return {
        type: actionTypes.COMPRAR_PAGAMENTO_SUCCESS_START
    }
};

export const comprarAtendimentoSuccess = (atendimento) => {
    return {
        type: actionTypes.COMPRAR_ATENDIMENTO_SUCCESS,
        atendimento: atendimento
    }
};

export const comprarAtendimentoFail = (error) => {
    return {
        type: actionTypes.COMPRAR_ATENDIMENTO_FAIL,
        error: error
    }
};

export const comprarAtendimento = (comprar, userId, noCompra = null) => {
    return dispatch => {
        dispatch(comprarAtendimentoStart());
        let comprarAux = {...comprar, ...{status:'aguardandopagamento', statusName: 'Aguardando Pagamento', desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }};
        
        if(noCompra){
            comprarAux = comprar;
        }

        FireBase.db.collection('atendimentos')
                    .add(comprarAux)
                    .then((response) => {
                        
                        let atendimentoAux = {...{id:response.id},...comprarAux}
                        dispatch(comprarAtendimentoSuccess(atendimentoAux));
                        //dispatch(comprarClean());
                    })
                    .catch(error => {
                        dispatch(comprarAtendimentoFail(error));
                    });
    }
};

export const comprarPagamentoStart = () => {
    return {
        type: actionTypes.COMPRAR_PAGAMENTO_START
    }
};

export const comprarPagamentoSuccess = (atendimento) => {
    return {
        type: actionTypes.COMPRAR_PAGAMENTO_SUCCESS,
        atendimento: atendimento
    }
};

export const comprarPagamentoFail = (error) => {
    return {
        type: actionTypes.COMPRAR_PAGAMENTO_FAIL,
        error: error
    }
};

export const comprarPagamento = (id, pagamentoAdd, userId) => {
    return dispatch => {
        dispatch(comprarPagamentoStart());
        let pagamentoAux = {...{pagamento: pagamentoAdd}, ...{userIdUpdate: userId, updated: FireBase.timeStamp() }};
        
        FireBase.db.collection('atendimentos')
            .doc(id).update(pagamentoAux)
            .then((response) => {
                dispatch(comprarPagamentoSuccess());
                dispatch(comprarClean());
            })
            .catch(error => {
                dispatch(comprarPagamentoFail(error));
            });
    }
};

//GET ATENDIMENTO
export const setComprarAtendimentoStart = () => {
    return {
        type: actionTypes.SET_COMPRAR_ATENDIMENTO_START
    }
}

export const getComprarAtendimento = (id) => {
    return dispatch =>{
        dispatch(setComprarAtendimentoStart());
        
        FireBase.db.collection('atendimentos')
            .doc(id)
            .get()
            .then(querySnapshot => {
                const atendimentoAux = {id: querySnapshot.id, ...querySnapshot.data()};
                dispatch(setComprarAtendimentoSuccess(atendimentoAux));
            });
    }  
}

export const setComprarAtendimentoSuccess = (atendimento) => {
    return {
        type: actionTypes.SET_COMPRAR_ATENDIMENTO_SUCCESS,
        atendimento: atendimento
    }
}