import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging'

import FirebaseAuth from './firebaseAuth';
import FirebaseStorage from './firebaseStorage';

let config = {
    apiKey: "AIzaSyAZEHP_7Tt4cbwaAUmEyfNYYCCBuK_NfVY",
    authDomain: "vcardsolutions.firebaseapp.com",
    databaseURL: "https://vcardsolutions.firebaseio.com",
    projectId: "vcardsolutions",
    storageBucket: "vcardsolutions.appspot.com",
    messagingSenderId: "380988339676",
    appId: "1:380988339676:web:0d2245b8a1e819800d0b19"
};

if(process.env.NODE_ENV === 'production'){
    config = {
        apiKey: "AIzaSyDuyT05-XznnrUOLa5At4gSmpcYasdFQKI",
        authDomain: "zapsite-d6dfd.firebaseapp.com",
        databaseURL: "https://zapsite-d6dfd.firebaseio.com",
        projectId: "zapsite-d6dfd",
        storageBucket: "zapsite-d6dfd.appspot.com",
        messagingSenderId: "906609299839",
        appId: "1:906609299839:web:787ba84ebac65bc6130a4a"
    }
}

class Firebase {
    constructor() {        
        app.initializeApp(config);
        
        this.auth = new FirebaseAuth(app.auth(), app.auth);
        this.storage = new FirebaseStorage(app);
        if (app.messaging.isSupported() && this.auth.currentUser){
            console.log(this.auth)
            this.messaging = app.messaging();
        
            if(process.env.NODE_ENV === 'production'){
                this.messaging.usePublicVapidKey('BP_JHqBxdekZfg1qPEB_fEQXz2p-13pKh6ar2Wg0KxMv6M54d2DcqB0IJjWgPZQa0QkvWlhR7RMJxJfYf2zMFs8');
            }else{
                this.messaging.usePublicVapidKey('BA3pn-HzwAcdPeTCcknKRDxu03czlCsGNklZ13lzmlRoM4YODlbQXqwEWpPQFvPhjwnEXRv6LkMi4YK2dAiYw6E');
            }
            
            this.messaging.requestPermission()
                .then(()=>{
                    this.messaging.getToken()
                        .then(currentToken=>{
                            localStorage.setItem("tokennotification", currentToken);
    
                            this.auth.doAuthStateChanged(authUser => {
                                if(authUser){
                                    this.db.collection('tokennotification')
                                        .doc(authUser.uid)
                                        .set({token: currentToken})
                                }
                            });
                        })
                        .catch(err=>{
                            console.log(err)
                        })
                })
                .catch(err=>{
                    console.log(err)
                })
    
            this.messaging.onTokenRefresh(() => {
                this.messaging.getToken().then((refreshedToken) => {
                    localStorage.removeItem("tokennotification");
    
                    localStorage.setItem("tokennotification", refreshedToken);
    
                    this.auth.doAuthStateChanged(authUser => {
                        if(authUser){
                            this.db.collection('tokennotification')
                                .doc(authUser.uid)
                                .set({token: refreshedToken})
                        }
                    });
                }).catch((err) => {
                    console.log('Unable to retrieve refreshed token ', err);
                });
    
            });
    
            this.messaging.onMessage(payload=>{
                let obj = payload.notification;
                let link = payload.fcmOptions.link;
                let notification = new Notification(obj.title,
                    {
                        icon: '/logo.png',
                        body: obj.body
                    });
                    
                notification.onclick = (e) => {
                    window.location = link;
                }
            })
        }
    }
    
    initDB = (classDB) =>{
        this.db = new classDB(app);
        this.timeStamp = this.db.serverTimestamp;
    }
}

const instanceFirebase = new Firebase();

export default instanceFirebase;