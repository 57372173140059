import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

export const registerClean = () => {
    return {
        type: actionTypes.REGISTER_CLEAN
    }
};

export const registerCleanError = () => {
    return {
        type: actionTypes.REGISTER_CLEANERROR
    }
};

export const registerCleanSuccess = () => {
    return {
        type: actionTypes.REGISTER_CLEANSUCCESS
    }
};

export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
};

export const registerSuccess = (userId, userName) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        userId: userId,
        userName: userName
    }
};

export const registerFail = (error) => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: error
    }
};

export const register = (register) => {
    return dispatch => {
        dispatch(registerStart());
        
        let resgisterAux = {...register, funcao:'usuario'};
        
        delete resgisterAux.password;

        FireBase.auth.doCreateUserWithEmailAndPassword(register.email, register.password)
            .then((res)=>{
                const user = {...resgisterAux, ...{desativado: false, created: FireBase.timeStamp() }};

                const nomeAux = user.name 
                    ? user.name 
                    : (user.nomefantasia ? user.nomefantasia : user.razaosocial);

                FireBase.db.collection('users').doc(res.user.uid)
                    .set(user)
                    .then((response) => {
                        dispatch(registerSuccess(res.user.uid, nomeAux));
                        dispatch(registerCleanSuccess());
                    },
                    error=>{
                        dispatch(registerFail(error));
                    })
                    .catch(error => {
                        dispatch(registerFail(error));
                    });
            })
            .catch(err=>{
                dispatch(registerFail(err));
            });
    }
};