import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeFornecedores = () => {};

//DELETE
export const deleteFornecedorStart = () => {
    return {
        type: actionTypes.DELETE_FORNECEDOR_START
    };
}

export const deleteFornecedor = (id, userId) => {
    return dispatch => {
        
        dispatch(deleteFornecedorStart());

        FireBase.db.collection('caixa')
            .where('uid_fornecedor', '==', id)
            .where('status', '==', 'fechado')
            .get()
            .then(res=>{
                if(res.size > 0){
                    dispatch(deleteFornecedorFail('Existe funções usando essa ação!'));
                }else{
                    FireBase.db.collection('fornecedores')
                        .doc(id)
                        .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
                        .then((response) => {
                            dispatch(deleteFornecedorSuccess());
                        })
                        .catch(error => {
                            dispatch(deleteFornecedorFail(error));
                        });
                }                
            })
            .catch(error => {
                dispatch(deleteFornecedorFail(error));
            });
    }
}

export const deleteFornecedorSuccess = () => {
    return {
        type: actionTypes.DELETE_FORNECEDOR_SUCCESS
    };
};

export const deleteFornecedorFail = (error) => {
    return {
        type: actionTypes.DELETE_FORNECEDOR_FAIL,
        error: error
    };
};

//ADICIONAR E ATUALIZAR
export const addFornecedorStart = () => {
    return {
        type: actionTypes.ADD_FORNECEDOR_START
    };
}

export const addFornecedor = (fornecedor, userId, update = null) => {
    return dispatch => {
        if(!update){
            fornecedor = {...fornecedor, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            fornecedor = {...fornecedor, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        
        dispatch(addFornecedorStart());
        if(update === null){
            FireBase.db.collection('fornecedores')
                .where('name', '==', fornecedor.name)
                .where('desativado', '==', false)
                .get()
                .then(res=>{
                    if(res.size > 0){
                        dispatch(addFornecedorFail('Já existe uma ação com esse nome!'));
                    }else{
                        FireBase.db.collection('fornecedores')
                            .add(fornecedor)
                            .then((response) => {
                                dispatch(addFornecedorSuccess());
                            })
                            .catch(error => {
                                dispatch(addFornecedorFail(error));
                            });
                    }                
                })
                .catch(error => {
                    dispatch(addFornecedorFail(error));
                });
        }else{
            FireBase.db.collection('fornecedores')
                .doc(update).update(fornecedor)
                .then((response) => {
                    dispatch(addFornecedorSuccess());
                })
                .catch(error => {
                    dispatch(addFornecedorFail(error));
                });
        }
    }
}

export const addFornecedorSuccess = () => {
    return {
        type: actionTypes.ADD_FORNECEDOR_SUCCESS,
        fornecedores: [],
        fornecedorEdit: [],
    };
};

export const addFornecedorFail = (error) => {
    return {
        type: actionTypes.ADD_FORNECEDOR_FAIL,
        error: error.name
    };
};
// GET ALL
export const setFornecedoresStart = () => {
    return {
        type: actionTypes.SET_FORNECEDORES_START
    }
}

export const getFornecedores = (header = null, options = null) => {
    return dispatch =>{
        dispatch(setFornecedoresStart());
        
        unsubscribeFornecedores = FireBase.db.collection('fornecedores')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
               
                querySnapshot.forEach(function(doc) {
                
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setFornecedoresSuccess(docAux));
            });
    }  
}

export const setFornecedoresSuccess = (fornecedores) => {
    return {
        type: actionTypes.SET_FORNECEDORES_SUCCESS,
        fornecedores: fornecedores
    }
}

export const setFornecedoresFail = (error) => {
    return {
        type: actionTypes.SET_FORNECEDORES_FAIL,
        error: error
    }
}

//GET
export const setFornecedorStart = () => {
    return {
        type: actionTypes.SET_FORNECEDOR_START
    }
}

export const getFornecedor = (id) => {
    return dispatch =>{
        dispatch(setFornecedorStart());
        
        FireBase.db.collection('fornecedores')
            .doc(id).get()
            .then(doc => {
                const docAux = doc.data();
                docAux.id = doc.id;
                dispatch(setFornecedorSuccess(docAux));
            })
            .catch(error=>{
                dispatch(setFornecedorFail(error));
            });
    }  
}

export const setFornecedorSuccess = (fornecedor) => {
    return {
        type: actionTypes.SET_FORNECEDOR_SUCCESS,
        fornecedorEdit: fornecedor
    }
}

export const setFornecedorFail = (error) => {
    return {
        type: actionTypes.SET_FORNECEDOR_FAIL,
        error: error
    }
}
// EXIT FORNECEDOR
export const exitFornecedor = () => {
    return dispatch => {
        unsubscribeFornecedores();
        dispatch(exitFornecedorSuccess());
    }
}

export const exitFornecedorSuccess = () => {
    return {
        type: actionTypes.EXIT_FORNECEDORES
    }
}