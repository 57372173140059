import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeUnidades = () => {};

//DELETE
export const deleteUnidadeStart = () => {
    return {
        type: actionTypes.DELETE_UNIDADE_START
    };
}

export const deleteUnidade = (id, userId) => {
    return dispatch => {
        
        dispatch(deleteUnidadeStart());

        FireBase.db.collection('caixa')
            .where('uid_unidade', '==', id)
            .where('status', '==', 'fechado')
            .get()
            .then(res=>{
                if(res.size > 0){
                    dispatch(deleteUnidadeFail('Existe funções usando essa ação!'));
                }else{
                    FireBase.db.collection('unidades')
                        .doc(id)
                        .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
                        .then((response) => {
                            dispatch(deleteUnidadeSuccess());
                        })
                        .catch(error => {
                            dispatch(deleteUnidadeFail(error));
                        });
                }                
            })
            .catch(error => {
                dispatch(deleteUnidadeFail(error));
            });
    }
}

export const deleteUnidadeSuccess = () => {
    return {
        type: actionTypes.DELETE_UNIDADE_SUCCESS
    };
};

export const deleteUnidadeFail = (error) => {
    return {
        type: actionTypes.DELETE_UNIDADE_FAIL,
        error: error
    };
};

//ADICIONAR E ATUALIZAR
export const addUnidadeStart = () => {
    return {
        type: actionTypes.ADD_UNIDADE_START
    };
}

export const addUnidade = (unidade, userId, update = null) => {
    return dispatch => {
        if(!update){
            unidade = {...unidade, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            unidade = {...unidade, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        
        dispatch(addUnidadeStart());
        if(update === null){
            FireBase.db.collection('unidades')
                .where('name', '==', unidade.name)
                .where('desativado', '==', false)
                .get()
                .then(res=>{
                    if(res.size > 0){
                        dispatch(addUnidadeFail('Já existe uma ação com esse nome!'));
                    }else{
                        FireBase.db.collection('unidades')
                            .add(unidade)
                            .then((response) => {
                                dispatch(addUnidadeSuccess());
                            })
                            .catch(error => {
                                dispatch(addUnidadeFail(error));
                            });
                    }                
                })
                .catch(error => {
                    dispatch(addUnidadeFail(error));
                });
        }else{
            FireBase.db.collection('unidades')
                .doc(update).update(unidade)
                .then((response) => {
                    dispatch(addUnidadeSuccess());
                })
                .catch(error => {
                    dispatch(addUnidadeFail(error));
                });
        }
    }
}

export const addUnidadeSuccess = () => {
    return {
        type: actionTypes.ADD_UNIDADE_SUCCESS,
        unidades: [],
        unidadeEdit: [],
    };
};

export const addUnidadeFail = (error) => {
    return {
        type: actionTypes.ADD_UNIDADE_FAIL,
        error: error
    };
};
// GET ALL
export const setUnidadesStart = () => {
    return {
        type: actionTypes.SET_UNIDADES_START
    }
}

export const getUnidades = (header = null, options = null) => {
    return dispatch =>{
        dispatch(setUnidadesStart());
        
        unsubscribeUnidades = FireBase.db.collection('unidades')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setUnidadesSuccess(docAux));
            });
    }  
}

export const setUnidadesSuccess = (unidades) => {
    return {
        type: actionTypes.SET_UNIDADES_SUCCESS,
        unidades: unidades
    }
}

export const setUnidadesFail = (error) => {
    return {
        type: actionTypes.SET_UNIDADES_FAIL,
        error: error
    }
}

//GET
export const setUnidadeStart = () => {
    return {
        type: actionTypes.SET_UNIDADE_START
    }
}

export const getUnidade = (id) => {
    return dispatch =>{
        dispatch(setUnidadeStart());
        
        FireBase.db.collection('unidades')
            .doc(id).get()
            .then(doc => {
                const docAux = doc.data();
                docAux.id = doc.id;
                dispatch(setUnidadeSuccess(docAux));
            })
            .catch(error=>{
                dispatch(setUnidadeFail(error));
            });
    }  
}

export const setUnidadeSuccess = (unidade) => {
    return {
        type: actionTypes.SET_UNIDADE_SUCCESS,
        unidadeEdit: unidade
    }
}

export const setUnidadeFail = (error) => {
    return {
        type: actionTypes.SET_UNIDADE_FAIL,
        error: error
    }
}
// EXIT UNIDADE
export const exitUnidade = () => {
    return dispatch => {
        unsubscribeUnidades();
        dispatch(exitUnidadeSuccess());
    }
}

export const exitUnidadeSuccess = () => {
    return {
        type: actionTypes.EXIT_UNIDADES
    }
}