import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    unidades: [],
    unidadeEdit: [],
    error: null,
    loading: false,
    add: false
}

const deleteUnidadeSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteUnidadeFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const addUnidadeSuccess = (state, action) => {
    const newState = {
        unidades: action.unidades,
        unidadeEdit: action.unidadeEdit, 
        add: true
    }
    return updateObject(state,newState);
}

const addUnidadeFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}
const clearUnidade = (state, action) => {
    const exit = {
        unidades: [],
        unidadeEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_UNIDADE_START:
            return updateObject(state, {loading: true});

        case actionTypes.DELETE_UNIDADE_SUCCESS:
            return deleteUnidadeSuccess(state, action); 

        case actionTypes.DELETE_UNIDADE_FAIL:
            return deleteUnidadeFail(state, action);

        case actionTypes.ADD_UNIDADE_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_UNIDADE_SUCCESS:
            return addUnidadeSuccess(state, action); 

        case actionTypes.ADD_UNIDADE_FAIL:
            return addUnidadeFail(state, action);

        case actionTypes.SET_UNIDADES_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_UNIDADES_SUCCESS: 
            return updateObject(state, {unidades: action.unidades, loading: false});

        case actionTypes.SET_UNIDADES_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.SET_UNIDADE_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_UNIDADE_SUCCESS: 
            return updateObject(state, {unidadeEdit: action.unidadeEdit, loading: false});

        case actionTypes.SET_UNIDADE_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_UNIDADES: 
            return clearUnidade(state, action);   

        default:
            return state;
    }
}

export default reducer;