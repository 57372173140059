//AUTH
export {
    auth,
    logout,
    authCleanError,
    getAcesso
} from './auth';

//REGISTER
export {
    register,
    registerCleanError,
    registerClean
} from './register';

//Contato
export {
    addContato,
    contatoCleanSucces
} from './contato';

//FORGOTPASS
export {
    forgotpass,
    forgotpassCleanError
} from './forgotpass';

//COMPRAR
export {
    comprarAtendimento,
    comprarAtendimentoStart,
    comprarPagamento,
    comprarPagamentoStart,
    comprarPagamentoStartSuccess, 
    comprarPagamentoFail, 
    getComprarAtendimento,
    comprarClean
} from './comprar';

//ATENDER
export {
    getAtendimento,
    getAtendimentos,
    atendimentoClean,
    setAtendimentoStatus,
    anexarAtendimento,
    deleteAnexoAtendimento,
    exitAtendimento
} from './atendimento';

//VCARDS
export {
    getVcard,
    getVcards,
    getVcardsAtendimento, 
    getVcardsUser,
    addVcard, 
    anexarVcard,
    addVcardsFav,
    deleteAnexoVcard, 
    setVcardStatus,
    addVcardAnotacao,
    addVcardAnotacaoClear, 
    getVcardsAnotacaoUser,
    vcardClean,
    biVcard,
    getBiVcard,
    exitVcard
} from './vcard';

//CADASTRO
export {
    getUsers,
    getUser,
    addUser,
    deleteUser,
    anexarFotoUser,
    deleteAnexoFotoUser,
    getImages, 
    exitUser
} from './user';

//CHAT
export {
    getChat,
    getChats,
    getChatMsg,
    addChat,
    addChatVideo,
    addChatAnexo,
    cleanChatVideo,
    exitChat
} from './chat';

export {
    getFornecedores,
    getFornecedor,
    addFornecedor,
    deleteFornecedor,
    exitFornecedor
} from './fornecedores';

export {
    getCategorias,
    getSubCategorias,
    getCategoria,
    addCategoria,
    deleteCategoria,
    exitCategorias
} from './categorias';

export {
    getEstados,
    exitEstados
} from './estadoscidades';

export {
    getUnidades,
    getUnidade,
    addUnidade,
    deleteUnidade,
    exitUnidade
} from './unidades';

//SEGURANCA
export {
    getAcoes,
    addAcoes,
    deleteAcoes,
    exitAcoes,
    getOpcoes
} from './acoes';

export {
    getFuncoes,
    getFuncao,
    addFuncao,
    deleteFuncao,
    exitFuncoes
} from './funcoes';

//AUX
export {
    openNotification,
    closeNotification
} from './notification';