import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeCategorias = () => {};
let unsubscribeSubCategorias = () => {};

//DELETE
export const deleteCategoriaStart = () => {
    return {
        type: actionTypes.DELETE_CATEGORIA_START
    };
}

export const deleteCategoria = (id, userId, idCategoria = null) => {
    return dispatch => {
        
        dispatch(deleteCategoriaStart());

        
        let auxDeleteCategoria = FireBase.db.collection('categorias')
        
        if(idCategoria){
            auxDeleteCategoria = auxDeleteCategoria.doc(idCategoria).collection('subcategorias').doc(id)
        }else{
            auxDeleteCategoria = auxDeleteCategoria.doc(id)
        }
        auxDeleteCategoria
            .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
            .then((response) => {
                dispatch(deleteCategoriaSuccess());
            })
            .catch(error => {
                dispatch(deleteCategoriaFail(error));
            });    
    }
}

export const deleteCategoriaSuccess = () => {
    return {
        type: actionTypes.DELETE_CATEGORIA_SUCCESS
    };
};

export const deleteCategoriaFail = (error) => {
    return {
        type: actionTypes.DELETE_CATEGORIA_FAIL,
        error: error
    };
};

//ADICIONAR E ATUALIZAR
export const addCategoriaStart = () => {
    return {
        type: actionTypes.ADD_CATEGORIA_START
    };
}

export const addCategoria = (categoria, userId, update = null, idCategoria = null) => {
    return dispatch => {
        if(!update){
            categoria = {...categoria, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            categoria = {...categoria, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        dispatch(addCategoriaStart());

        if(idCategoria){            
            if(update === null){
                FireBase.db.collection('categorias')
                    .doc(idCategoria)
                    .collection('subcategorias')
                    .where('nome', '==', categoria.nome)
                    .get()
                    .then(res=>{
                        if(res.size > 0){
                            dispatch(addCategoriaFail('Já existe uma subcategoria com esse nome!'));
                        }else{
                            console.log('aqui 1')
                            FireBase.db.collection('categorias')
                                .doc(idCategoria)
                                .collection('subcategorias')
                                .add(categoria)
                                .then((response) => {
                                    console.log('aqui 2')
                                    dispatch(addCategoriaSuccess());
                                })
                                .catch(error => {
                                    console.log('aqui 3')
                                    dispatch(addCategoriaFail(error));
                                });
                        }                
                    })
                    // .catch(error => {
                    //     console.log('aqui 4')
                    //     dispatch(addCategoriaFail(error));
                    // });
            }else{
                FireBase.db.collection('categorias')
                    .doc(idCategoria)
                    .collection('subcategorias')
                    .doc(update).update(categoria)
                    .then((response) => {
                        dispatch(addCategoriaSuccess());
                    })
                    .catch(error => {
                        dispatch(addCategoriaFail(error));
                    });
            }
            return;
        }
        
        if(update === null){
            FireBase.db.collection('categorias')
                .where('nome', '==', categoria.nome)
                .get()
                .then(res=>{
                    if(res.size > 0){
                        dispatch(addCategoriaFail('Já existe uma categoria com esse nome!'));
                    }else{
                        console.log('aqui 1')
                        FireBase.db.collection('categorias')
                            .add(categoria)
                            .then((response) => {
                                console.log('aqui 2')
                                dispatch(addCategoriaSuccess());
                            })
                            .catch(error => {
                                console.log('aqui 3')
                                dispatch(addCategoriaFail(error));
                            });
                    }                
                })
                // .catch(error => {
                //     console.log('aqui 4')
                //     dispatch(addCategoriaFail(error));
                // });
        }else{
            FireBase.db.collection('categorias')
                .doc(update).update(categoria)
                .then((response) => {
                    dispatch(addCategoriaSuccess());
                })
                .catch(error => {
                    dispatch(addCategoriaFail(error));
                });
        }
    }
}

export const addCategoriaSuccess = () => {
    return {
        type: actionTypes.ADD_CATEGORIA_SUCCESS,
        categorias: [],
        categoriaEdit: [],
    };
};

export const addCategoriaFail = (error) => {
    return {
        type: actionTypes.ADD_CATEGORIA_FAIL,
        error: error
    };
};

// GET ALL
export const setCategoriasStart = () => {
    return {
        type: actionTypes.SET_CATEGORIAS_START
    }
}

export const getCategorias = (header = null, options = null, id = null) => {
    return dispatch =>{
        dispatch(setCategoriasStart());
        
        unsubscribeCategorias = FireBase.db.collection('categorias');

        console.log(id)
        if(id){
            console.log(id)
            unsubscribeCategorias = unsubscribeCategorias.doc(id).collection('subcategorias')
        }

        unsubscribeCategorias =   unsubscribeCategorias.orderBy('nome', 'asc')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        d.id = doc.id;
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setCategoriasSuccess(docAux));
            });
    }  
}

export const setCategoriasSuccess = (categorias) => {
    return {
        type: actionTypes.SET_CATEGORIAS_SUCCESS,
        categorias: categorias
    }
}

export const setCategoriasFail = (error) => {
    return {
        type: actionTypes.SET_CATEGORIAS_FAIL,
        error: error
    }
}

//GET
export const setCategoriaStart = () => {
    return {
        type: actionTypes.SET_CATEGORIA_START
    }
}

export const getCategoria = (id, idCategoria = null) => {
    return dispatch =>{
        dispatch(setCategoriaStart());
        
        let auxGetCategoria = FireBase.db.collection('categorias')
        
        if(idCategoria){
            auxGetCategoria = auxGetCategoria.doc(idCategoria).collection('subcategorias').doc(id)
        }else{
            auxGetCategoria = auxGetCategoria.doc(id)
        }

        auxGetCategoria.get()
            .then(doc => {
                const docAux = doc.data();
                docAux.id = doc.id;
                dispatch(setCategoriaSuccess(docAux));
            })
            .catch(error=>{
                console.log(error)
                dispatch(setCategoriaFail(error));
            });
    }  
}

export const setCategoriaSuccess = (categoria) => {
    console.log(categoria)
    return {
        type: actionTypes.SET_CATEGORIA_SUCCESS,
        categoriaEdit: categoria
    }
}

export const setCategoriaFail = (error) => {
    return {
        type: actionTypes.SET_CATEGORIA_FAIL,
        error: error
    }
}

// EXIT CATEGORIA
export const exitCategorias = () => {
    return dispatch => {
        unsubscribeCategorias();
        unsubscribeSubCategorias();
        dispatch(exitCategoriaSuccess());
    }
}

export const exitCategoriaSuccess = () => {
    return {
        type: actionTypes.EXIT_CATEGORIAS
    }
}


// GET ALL SUB
export const setSubCategoriasStart = () => {
    return {
        type: actionTypes.SET_SUB_CATEGORIAS_START
    }
}

export const getSubCategorias = (id = null) => {
    return dispatch =>{
        dispatch(setSubCategoriasStart());
        
        unsubscribeSubCategorias = FireBase.db.collection('categorias')
            .doc(id)
            .collection('subcategorias')
            .orderBy('nome', 'asc')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let d = doc.data();                    
                    d.id = doc.id;                        
                    lines = lines.concat([d]);
                });

                docAux = lines;
                dispatch(setSubCategoriasSuccess(docAux));
            });
    }  
}

export const setSubCategoriasSuccess = (subcategorias) => {
    return {
        type: actionTypes.SET_SUB_CATEGORIAS_SUCCESS,
        subcategorias: subcategorias
    }
}

export const setSubCategoriasFail = (error) => {
    return {
        type: actionTypes.SET_SUB_CATEGORIAS_FAIL,
        error: error
    }
}