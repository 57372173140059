import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    forgotpass: false,
    success: null,
    error: null,
    loading: false,
};

const forgotpassStart = (state, action) => {
    const updateState = {
        forgotpass: true,
        loading: true,
        error: null
    }
    return updateObject(state, updateState);
}

const forgotpassSuccess = (state, action) => {
    const updateState = {
        forgotpass: false,
        error: null,
        loading: false,
        success: true
    }
    return updateObject(state, updateState);
}

const forgotpassCleanError = (state, action) => {
    const updateState = {
        error: null
    }
    return updateObject(state, updateState);
}

const forgotpassCleanSuccess = (state, action) => {
    const updateState = {
        success: null
    }
    return updateObject(state, updateState);
}

const forgotpassFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error.code){
        case 'auth/user-not-found':
            errorMsg = 'O E-mail não cadastrado';
            break;
        default:
            errorMsg = 'Error para criar uma conta por favor entrar em contato com assistência.'
    }
    const updateState = {
        forgotpass: false,
        loading: false,
        error: errorMsg
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FORGOTPASS_START:
            return forgotpassStart(state, action);
        case actionTypes.FORGOTPASS_SUCCESS:
            return forgotpassSuccess(state, action);
        case actionTypes.FORGOTPASS_FAIL:
            return forgotpassFail(state, action);
        case actionTypes.FORGOTPASS_CLEANSUCCESS:
            return forgotpassCleanSuccess(state, action);
        case actionTypes.FORGOTPASS_CLEANERROR:
            return forgotpassCleanError(state, action);
        default:
            return state
    }
}

export default reducer;