import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    users: [],
    userEdit: [],
    error: null,
    loading: false,
    add: false,
    imageUser: null,
    loadingImages: null,
}

const deleteUserSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteUserFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const addUserSuccess = (state, action) => {
    const newState = {
        users: action.users,
        userEdit: action.userEdit, 
        add: true
    }
    return updateObject(state,newState);
}

const addUserFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

// GET IMAGE
const setVcardImageSuccess = (state, action) => {
    const updateState = {
        imageUser: action.imageUser
    }
    
    return updateObject(state, updateState);
}

const clearUser = (state, action) => {
    const exit = {
        users: [],
        userEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_USER_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.DELETE_USER_SUCCESS:
            return deleteUserSuccess(state, action); 

        case actionTypes.DELETE_USER_FAIL:
            return deleteUserFail(state, action);

        case actionTypes.ADD_USER_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_USER_SUCCESS:
            return addUserSuccess(state, action); 

        case actionTypes.ADD_USER_FAIL:
            return addUserFail(state, action);

        case actionTypes.SET_USERS_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_USERS_SUCCESS: 
            return updateObject(state, {users: action.users, loading: false});

        case actionTypes.SET_USERS_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.SET_USER_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_USER_SUCCESS: 
            return updateObject(state, {userEdit: action.userEdit, loading: false});

        case actionTypes.SET_USER_FAIL: 
            return updateObject(state, {loading: false});

        //ANEXAR FOTO USER
        case actionTypes.ANEXAR_FOTO_USER_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.ANEXAR_FOTO_USER_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        //DETELE FOTO USER
        case actionTypes.DELETE_ANEXO_FOTO_USER_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.DELETE_ANEXO_FOTO_USER_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});
        
        //GET IMAGE
        case actionTypes.SET_USER_IMAGE_SUCCESS:
            return setVcardImageSuccess(state, action);
        case actionTypes.SET_USER_IMAGE_START:
            return updateObject(state, {loadingImages: true});
        case actionTypes.SET_USER_IMAGE_FINISH:
            return updateObject(state, {loadingImages: false});
       
        case actionTypes.EXIT_USERS: 
            return clearUser(state, action);   

        default:
            return state;
    }
}

export default reducer;