import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeUsers = () => {};

//DELETE
export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START
    };
}

export const deleteUser = (id, userId) => {
    return dispatch => {
        
        dispatch(deleteUserStart());

        FireBase.db.collection('users')
            .doc(id)
            .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
            .then(res=>{
                dispatch(deleteUserSuccess());           
            })
            .catch(error => {
                dispatch(deleteUserFail(error));
            });
    }
}

export const deleteUserSuccess = () => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS
    };
};

export const deleteUserFail = (error) => {
    return {
        type: actionTypes.DELETE_USER_FAIL,
        error: error
    };
};

//ADICIONAR E ATUALIZAR
export const addUserStart = () => {
    return {
        type: actionTypes.ADD_USER_START
    };
}

export const addUser = (user, userId, update = null) => {
    return dispatch => {
        if(!update){
            user = {...user, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            user = {...user, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        const passAux = user.password;
        delete user.password;
        const currentPassAux = user.currentPass;
        delete user.currentPass;
        
        dispatch(addUserStart());
        if(update === null){
           FireBase.db.collection('users')
            .add(user)
            .then((response) => {
                dispatch(addUserSuccess());
            })
            .catch(error => {
                dispatch(addUserFail(error));
            });
        }else{
            FireBase.db.collection('users')
                .doc(update)
                .set(
                    user,
                    {merge: true}
                )
                .then((response) => {
                    if(passAux && update === FireBase.auth.authInit.currentUser.uid) {
                        reauthenticate(user.email, currentPassAux)
                            .then(()=>{
                                FireBase.auth.doPasswordUpdate(passAux).then(function(res) {
                                    dispatch(addUserSuccess());
                                })
                            });
                    }else{
                        dispatch(addUserSuccess());
                    }
                })
                .catch(error => {
                    console.log(error)
                    dispatch(addUserFail(error));
                });
        }
    }
}
const reauthenticate = (email, currentPassword) => {
    var cred = FireBase.auth.EmailAuthProvider.credential(
        email, currentPassword);
    return FireBase.auth.reauthenticateWithCredential(cred);
  }
export const addUserSuccess = () => {
    return {
        type: actionTypes.ADD_USER_SUCCESS,
        users: [],
        userEdit: [],
    };
};

export const addUserFail = (error) => {
    return {
        type: actionTypes.ADD_USER_FAIL,
        error: error
    };
};
// GET ALL
export const setUsersStart = () => {
    return {
        type: actionTypes.SET_USERS_START
    }
}

export const getUsers = (header = null, options = null) => {
    return dispatch =>{
        dispatch(setUsersStart());
        
        unsubscribeUsers = FireBase.db.collection('users')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setUsersSuccess(docAux));
            });
    }  
}

export const setUsersSuccess = (users) => {
    return {
        type: actionTypes.SET_USERS_SUCCESS,
        users: users
    }
}

export const setUsersFail = (error) => {
    return {
        type: actionTypes.SET_USERS_FAIL,
        error: error
    }
}

//GET
export const setUserStart = () => {
    return {
        type: actionTypes.SET_USER_START
    }
}

export const getUser = (id) => {
    return dispatch =>{
        dispatch(setUserStart());
        
        FireBase.db.collection('users')
            .doc(id).get()
            .then(doc => {
                const docAux = doc.data();
                docAux.id = doc.id;
                
                dispatch(setUserSuccess(docAux));
            })
            .catch(error=>{
                dispatch(setUserFail(error));
            });
    }  
}

export const setUserSuccess = (user) => {
    return {
        type: actionTypes.SET_USER_SUCCESS,
        userEdit: user
    }
}

export const setUserFail = (error) => {
    return {
        type: actionTypes.SET_USER_FAIL,
        error: error
    }
}

//ANEXAR FOTO_USER
export const anexarFotoUserStart = () => {
    return {
        type: actionTypes.ANEXAR_FOTO_USER_START
    }
}

export const anexarFotoUser = (userId, anexos) => {
    return dispatch =>{
        dispatch(anexarFotoUserStart());

        Object.keys(anexos).forEach(res=>{
            const path = `${userId}/fotoperfil/${res}__${userId}`;
            const uploadTask = FireBase.storage.ref(path).put(anexos[res]);
                    
            uploadTask.on('state_changed', 
            (snapshot) => {
                // progrss function ....
                
            }, 
            (error) => {
                // error function ....
                
            }, 
            () => {
                const anexo = {};
                anexo[res] = {path: path};
                //complete function ....
                FireBase.db.collection('users')
                    .doc(userId)
                    .set(   {anexo} ,
                            {merge: true}
                        )
                    .then(res=>{
                        dispatch(anexarFotoUserSuccess());
                    },
                    err=>{
                        
                    });
            });
        });
    }  
}

export const anexarFotoUserSuccess = () => {
    return {
        type: actionTypes.ANEXAR_FOTO_USER_SUCCESS
    }
}

//DELETAR ANEXO FOTO_USER
export const deleteAnexoFotoUserStart = () => {
    return {
        type: actionTypes.DELETE_ANEXO_FOTO_USER_START
    }
}

export const deleteAnexoFotoUser = (userId, anexo, item) => {
    return dispatch =>{
        dispatch(deleteAnexoFotoUserStart());
        const storageRef = FireBase.storage.ref();
        var desertRef = storageRef.child(anexo);

        desertRef.delete().then(res=>{
            let auxDeleteField = { [`anexo.${item}.path`]: FireBase.db.FieldValue.delete(),  };
    
            FireBase.db.collection('users')
                .doc(userId)
                .update(auxDeleteField)
                .then(res=>{
                    auxDeleteField = {  [ `anexo.${item}`]: FireBase.db.FieldValue.delete() };
                    FireBase.db.collection('users')
                        .doc(userId)
                        .update(auxDeleteField)
                        .then(res=>{
                            dispatch(deleteAnexoFotoUserSuccess());
                        });
                });  
        });
    }  
}

export const deleteAnexoFotoUserSuccess = () => {
    return {
        type: actionTypes.DELETE_ANEXO_FOTO_USER_SUCCESS
    }
}

// GET IMAGES
export const setUserImageStart = () => {
    return {
        type: actionTypes.SET_USER_IMAGE_START
    }
}

export const setUserImageFinish = () => {
    return {
        type: actionTypes.SET_USER_IMAGE_FINISH
    }
}

export const getImages = (userId) => {
    return dispatch =>{
        const storageRef = FireBase.storage.ref(userId);

        storageRef.child('fotoperfil/').listAll()
            .then(resList=>{
                if(resList.items.length === 0) {
                    dispatch(setUserImageStart());
                        
                    dispatch(setUserImageSuccess(''));
                    dispatch(setUserImageFinish());
                }
                resList.items.forEach(imageRef => {                    
                    imageRef.getDownloadURL().then(function(url) {
                        dispatch(setUserImageStart());
                        
                        dispatch(setUserImageSuccess(url));
                    })
                    .then(()=>{
                        dispatch(setUserImageFinish());
                    });
                });
            })
    }
}

export const setUserImageSuccess = (imageUser) => {
    return {
        type: actionTypes.SET_USER_IMAGE_SUCCESS,
        imageUser: imageUser
    }
}
// EXIT USER
export const exitUser = () => {
    return dispatch => {
        unsubscribeUsers();
        dispatch(exitUserSuccess());
    }
}

export const exitUserSuccess = () => {
    return {
        type: actionTypes.EXIT_USERS
    }
}