import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    userId: localStorage.getItem("userId") ? localStorage.getItem("userId") :null,
    user: null,
    acesso: null,
    error: null,
    loading: false
};

const authSuccess = (state, action) => {
    const updateState = {
        userId: action.authData.uid,
        error: null,
        loading: false
    }
    return updateObject(state, updateState);
}

const authSetAcesso = (state, action) => {
    const updateState = {
        user: action.doc,
        acesso: action.doc.acesso
    }
    return updateObject(state, updateState);
}

const authLogout = (state, action) => {
    const updateState = {
        userId: null,
        user: null,
        acesso: null
    }
    return updateObject(state, updateState);
}

const authCleanError = (state, action) => {
    const updateState = {
        error: null
    }
    return updateObject(state, updateState);
}

const authStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null
    }
    return updateObject(state, updateState);
}

const authFail = (state, action) => {
    
    let errorMsg = '';
    switch(action.error.code){
        case 'auth/user-not-found':
            errorMsg = 'Usuário e/ou senha inválidos.';
            break;
        case 'auth/wrong-password':
            errorMsg = 'Usuário e/ou senha inválidos.';
            break;
        case 'auth/user-token-expired':
            errorMsg = 'Seção encerada por favor refazer login.';
            break;
        default:
            errorMsg = 'Error no login por favor entrar em contato com assistência.'
    }
    const updateState = {
        loading: false,
        error: errorMsg
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_CLEANERROR:
            return authCleanError(state, action);
        case actionTypes.AUTH_SETACESSO:
            return authSetAcesso(state, action);
        default:
            return state
    }
}

export default reducer;