import React from 'react';

import classes from './Spinner.module.css';

const spinner = (props) => {
    let classAux = [classes.SpinnerContent];

    if(props.classAdd) {
        const classField = props.classAdd.map(res=>{
            if(!classes[res]){
                return res;
            }
            return classes[res];
        });
        classAux = classAux.concat(classField);
    }

    return (
        <div className={classAux.join(' ')}>
            <div className={classes.Loader}></div>
        </div>
    );
}
export default spinner;