import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeUser = () => {};

export const authCleanError = () => {
    return {
        type: actionTypes.AUTH_CLEANERROR
    }
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        authData: authData
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const getAcesso = (uid) => {
    return dispatch =>{
        unsubscribeUser = FireBase.db.collection('users').doc(uid)
            .onSnapshot(doc => {
                dispatch(setAcesso(doc.data()));
            });
    }
}

export const setAcesso = (doc) => {
    return {
        type: actionTypes.AUTH_SETACESSO,
        doc: doc
    }
}

export const logout = () => {
    return dispatch => {
        FireBase.auth.doSignOut().then(res=>{
            unsubscribeUser();
            dispatch(logoutSucess());
        });
    }
}

export const logoutSucess = () => {
    localStorage.removeItem("userId");
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        // if(localStorage.getItem("uidAnonymous") && 1!=1){
        //     let credential = FireBase.auth.credential(email, password);
        //     console.log(credential);
        //     FireBase.auth.linkWithCredential(credential)
        //         .then(res=>{
        //             localStorage.setItem("userId", res.user.uid);
        //             dispatch(authSuccess(res.user));
        //         })
        //         .catch(err=>{
        //             dispatch(authFail(err));
        //         });
        // }else{
        FireBase.auth.doSignInWithEmailAndPassword(email, password)
        .then(res=>{
            localStorage.setItem("userId", res.user.uid);
            dispatch(authSuccess(res.user));
        })
        .catch(err=>{
            dispatch(authFail(err));
        });
        // }
    }
};