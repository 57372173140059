class firebaseAuth {
    constructor(authInit, auth) {
        this.authInit = authInit;
        this.auth = auth;
        this.EmailAuthProvider = auth.EmailAuthProvider;
        this.currentUser = this.authInit.currentUser
    }
    
    signInAnonymously = () => this.authInit.signInAnonymously();

    doAuthStateChanged = (autuser) => this.authInit.onAuthStateChanged(autuser);

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.authInit.createUserWithEmailAndPassword(email, password);
    
    doSignInWithEmailAndPassword = (email, password) =>
        this.authInit.signInWithEmailAndPassword(email, password);
    
    doSignOut = () => this.authInit.signOut();

    doPasswordReset = email => this.authInit.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.authInit.currentUser.updatePassword(password);

    credential = (email, password) => 
        this.EmailAuthProvider.credential(email, password);

    reauthenticateWithCredential = (credential) => this.authInit.currentUser.reauthenticateWithCredential(credential);

    linkWithCredential = (credential) => this.authInit.currentUser.linkWithCredential(credential)
}
  
export default firebaseAuth;