import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeAcoes = () => {};

export const deleteAcoesStart = () => {
    return {
        type: actionTypes.DELETE_ACOES_START
    };
}

export const deleteAcoes = (id, userId) => {
    return dispatch => {
        
        dispatch(deleteAcoesStart());
        const auxField = 'acoes.'+id
        FireBase.db.collection('funcoes').where(auxField, '==', true).get()
            .then(res=>{
                if(res.size > 0){
                    dispatch(deleteAcoesFail('Existe funções usando essa ação!'));
                }else{
                    FireBase.db.collection('acoes')
                        .doc(id)
                        .set({desativado: true, userIdDelete: userId, deleted: FireBase.timeStamp()}, { merge: true })
                        .then((response) => {
                            dispatch(deleteAcoesSuccess());
                        })
                        .catch(error => {
                            dispatch(deleteAcoesFail(error));
                        });
                }                
            })
            .catch(error => {
                dispatch(deleteAcoesFail(error));
            });
    }
}

export const deleteAcoesSuccess = () => {
    return {
        type: actionTypes.DELETE_ACOES_SUCCESS
    };
};

export const deleteAcoesFail = (error) => {
    return {
        type: actionTypes.DELETE_ACOES_FAIL,
        error: error
    };
};

export const addAcoesStart = () => {
    return {
        type: actionTypes.ADD_ACOES_START
    };
}

export const addAcoes = (id = null, acoes, userId, update = null) => {
    return dispatch => {
        if(!update){
            acoes = {...acoes, ...{desativado: false, userIdCreate: userId, created: FireBase.timeStamp() }}
        }else{
            acoes = {...acoes, ...{desativado: false, userIdUpdate: userId, updated: FireBase.timeStamp() }}
        }
        
        dispatch(addAcoesStart());
        if(update === null){
            FireBase.db.collection('acoes').doc(id).get()
                .then(res=>{
                    if(res.exists && res.data().desativado === false){
                        dispatch(addAcoesFail('Já existe uma ação com esse nome!'));
                    }else{
                        FireBase.db.collection('acoes').doc(id).set(acoes)
                            .then((response) => {
                                dispatch(addAcoesSuccess());
                            })
                            .catch(error => {
                                dispatch(addAcoesFail(error));
                            });
                    }                
                })
                .catch(error => {
                    dispatch(addAcoesFail(error));
                });
        }else{
            FireBase.db.collection('acoes').doc(id).update(acoes)
                .then((response) => {
                    dispatch(addAcoesSuccess());
                })
                .catch(error => {
                    dispatch(addAcoesFail(error));
                });
        }
    }
}

export const addAcoesSuccess = () => {
    return {
        type: actionTypes.ADD_ACOES_SUCCESS,
        acoes: [],
        acoesEdit: [],
    };
};

export const addAcoesFail = (error) => {
    return {
        type: actionTypes.ADD_ACOES_FAIL,
        error: error
    };
};

export const setAcoesStart = () => {
    return {
        type: actionTypes.SET_ACOES_START
    }
}

export const getAcoes = (header, options) => {
    return dispatch =>{
        dispatch(setAcoesStart());
        
        unsubscribeAcoes = FireBase.db.collection('acoes')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = {header: header};
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    if(doc.id !== 'opcoes'){
                        let optionsAux = null;
                        if(options){
                            optionsAux = options;
                        
                            optionsAux = optionsAux
                                .map(item=>{
                                    return {...item, click: ()=>{item.click(doc.id)}};
                                })
                                .reduce((arr, el) => {
                                    return arr.concat(el);
                                }, []);
                        }

                        let d = doc.data();
                        
                        d.name = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }
                });
                
                docAux.lines = lines;

                dispatch(setAcoesSuccess(docAux));
            });
    }  
}

export const setAcoesSuccess = (acoes) => {
    return {
        type: actionTypes.SET_ACOES_SUCCESS,
        acoes: acoes
    }
}

export const setAcoesFail = (error) => {
    return {
        type: actionTypes.SET_ACOES_FAIL,
        error: error
    }
}

export const exitAcoes = () => {
    return dispatch => {
        unsubscribeAcoes();
        dispatch(exitAcoesSuccess());
    }
}

export const exitAcoesSuccess = () => {
    return {
        type: actionTypes.EXIT_ACOES
    }
}

export const setOpcoesStart = () => {
    return {
        type: actionTypes.SET_OPCOES_START
    }
}

export const getOpcoes = (id = null) => {
    return dispatch =>{
        dispatch(setOpcoesStart());
        
        FireBase.db.collection('acoes').doc('opcoes').get()
            .then(doc => {
                if(id){
                    FireBase.db.collection('acoes').doc(id).get()
                        .then(docID=>{
                            dispatch(setOpcoesSuccess(doc.data(), docID.data()));
                        })
                }else{
                    dispatch(setOpcoesSuccess(doc.data()));
                }
            });
    }  
}

export const setOpcoesSuccess = (opcoes, acoes = []) => {
    return {
        type: actionTypes.SET_OPCOES_SUCCESS,
        opcoes: opcoes,
        acoesEdit: acoes
    }
}

export const setOpcoesFail = (error) => {
    return {
        type: actionTypes.SET_OPCOES_FAIL,
        error: error
    }
}