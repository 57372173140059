import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    fornecedores: [],
    fornecedorEdit: [],
    error: null,
    loading: false,
    add: false
}

const deleteFornecedorSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteFornecedorFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const addFornecedorSuccess = (state, action) => {
    const newState = {
        fornecedores: action.fornecedores,
        fornecedorEdit: action.fornecedorEdit, 
        add: true
    }
    return updateObject(state,newState);
}

const addFornecedorFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}
const clearFornecedor = (state, action) => {
    const exit = {
        fornecedores: [],
        fornecedorEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_FORNECEDOR_START:
            return updateObject(state, {loading: true});

        case actionTypes.DELETE_FORNECEDOR_SUCCESS:
            return deleteFornecedorSuccess(state, action); 

        case actionTypes.DELETE_FORNECEDOR_FAIL:
            return deleteFornecedorFail(state, action);

        case actionTypes.ADD_FORNECEDOR_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_FORNECEDOR_SUCCESS:
            return addFornecedorSuccess(state, action); 

        case actionTypes.ADD_FORNECEDOR_FAIL:
            return addFornecedorFail(state, action);

        case actionTypes.SET_FORNECEDORES_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_FORNECEDORES_SUCCESS: 
            return updateObject(state, {fornecedores: action.fornecedores, loading: false});

        case actionTypes.SET_FORNECEDORES_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.SET_FORNECEDOR_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_FORNECEDOR_SUCCESS: 
            return updateObject(state, {fornecedorEdit: action.fornecedorEdit, loading: false});

        case actionTypes.SET_FORNECEDOR_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_FORNECEDORES: 
            return clearFornecedor(state, action);   

        default:
            return state;
    }
}

export default reducer;