import React, { Suspense, lazy, Component} from 'react';
import { Route, Switch } from 'react-router-dom';
import Logout from './containers/Auth/Logout/Logout';

import { connect } from 'react-redux';
import * as actionCreators from './store/actions';
import Spinner from './components/UI/Spinner/Spinner';
import { library, toHtml } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { faPlus, faQuestion, faQuestionCircle, faCircle, faClock, faChartBar, faFile, faVideo, faPlay, faPaperclip, faPaperPlane, faCalendarAlt, faFilter, faIdCard, faHome, faTrashAlt, faEdit, faUpload, faSearch, faDownload, faStar as noFav, faShareAlt, faQrcode, faUser, faBook, faHeadset, faCog, faShoppingCart, faSignOutAlt, faExpand, faCheckCircle, faTimesCircle, faCheck, faClipboard, faSignInAlt, faComments, faShoppingBasket} from '@fortawesome/free-solid-svg-icons';
import { faStar as fav } from '@fortawesome/free-regular-svg-icons';
import FirebaseAux from './hoc/FirebaseAux/FirebaseAux';
import { Helmet } from 'react-helmet';

library.add(faFacebook, faTwitter, faWhatsapp, faPlus, faQuestion, faQuestionCircle, faShoppingBasket, faCircle, faClock, faChartBar, faFile, faVideo, faPlay, faPaperclip, faPaperPlane, faCalendarAlt, faFilter, faIdCard, faHome, faTrashAlt, faEdit, faUpload, faSearch, faDownload, noFav, fav, faShareAlt, faQrcode, faUser, faBook, faHeadset, faCog, faShoppingCart, faSignOutAlt, faExpand, faCheckCircle, faTimesCircle, faCheck, faClipboard, faSignInAlt, faComments);

const asyncAdmin = lazy(() => import('./containers/Admin/Admin'));
const asyncAuth = lazy(() => import('./containers/Auth/Auth'));
const asyncRegister = lazy(() => import('./containers/Auth/Register/Register'));
const asyncForgotPass = lazy(() => import('./containers/Auth/ForgotPass/ForgotPass'));

class App extends Component {
	componentDidMount(){
		this.props.onClose();
	}
	
	render() {
		return (
			<>
				<Helmet>
					<title>ZappSite</title>
    				<meta name="description" content="Conheça meu Zappsite. Seja Bem vindo! Cartão digital 100% Seguro."/>
				</Helmet>
				<Suspense fallback={(<Spinner/>)}>
					<FirebaseAux/>
				</Suspense>
				<Suspense fallback={(<Spinner/>)}>
					<Switch>
						<Route path="/logout" component={Logout} />
						<Route path="/register" component={asyncRegister} />
						<Route path="/forgotpass" component={asyncForgotPass} />
						<Route path="/auth" component={asyncAuth} />
						<Route path="/" component={asyncAdmin} />
						<Route path="*" render={()=> <h1 style={{textAlign: 'center'}}>Not Found</h1>} />
					</Switch>
				</Suspense>
			</>
			);
	}
}

const mapStatetoProps = state => {
	return {
        estados: state.estadoscidadesReducer.estados,
        user: state.authReducer.user,
		isAuthenticated: state.authReducer.userId !== null,
		isAcesso: state.authReducer.acesso !== null,
		isRegister: state.registerReducer.register
	}
}

const mapDispatchtoProps = dispatch => {
	return {
        onGetEstados: ()=> dispatch(actionCreators.getEstados()),
		onLogout: ()=> dispatch(actionCreators.logout()),
        onClose: ()=> dispatch(actionCreators.closeNotification())
	}
};

export default connect(mapStatetoProps, mapDispatchtoProps)(App);