import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    open: false,
    content: '',
    classe: []
};

const open = (state, action) => {
    const updateState = !state.open 
    ? {
        open: true,
        content: action.content,
        classe: action.classe
    }
    : state;
    return updateObject(state, updateState);
}

const close = (state, action) => {
    const updateState = {
        open: false,
        content: '',
        classe: []
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_OPENSUCCESS:
            return open(state, action);
        case actionTypes.NOTIFICATION_CLOSE:
            return close(state, action);
        default:
            return state
    }
}

export default reducer;