import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Firebase from '../../utils/firebase/';
import FirebaseDB from "../../utils/firebase/firebaseDB";
import * as actionCreators from '../../store/actions';
import { connect } from 'react-redux';

class FirebaseAux extends Component {
	constructor(props) {
		super(props);
		Firebase.initDB(FirebaseDB);
	}

	componentDidMount() {
		this.listiner = Firebase.auth.doAuthStateChanged(authUser => {
			if((!authUser || !this.props.isAuthenticated) 
					&& !this.props.isRegister 
				&& this.props.location.pathname.substr(0,7) !== '/agenda'
				&& this.props.location.pathname.substr(0,1) !== '/'){
				this.props.onLogout();
				this.props.history.push('/auth');
			}
			console.log(authUser)
			if(authUser && authUser.isAnonymous){
				localStorage.setItem("uidAnonymous", authUser.uid);
			}
		});
		Firebase.db.enablePersistence()
			.catch(function(err) {
				if (err.code == 'failed-precondition') {
					// Multiple tabs open, persistence can only be enabled
					// in one tab at a a time.
					// ...
				} else if (err.code == 'unimplemented') {
					// The current browser does not support all of the
					// features required to enable persistence
					// ...
				}
			});
	}

	componentDidUpdate(prevProps){
		if(JSON.stringify(this.props.estados) !== JSON.stringify(prevProps.estados) && this.props.user){
			this.props.estados.forEach(res=>{
				if(res.sigla === this.props.user.UF){

					localStorage.removeItem("estado");
					localStorage.setItem("estado", res.nome);
				}
			})
		}
	}

	componentWillUnmount() {
		this.listiner();
    }
    
    render(){
        return <span></span>;
    }
};

const mapStatetoProps = state => {
	return {
        estados: state.estadoscidadesReducer.estados,
        user: state.authReducer.user,
		isAuthenticated: state.authReducer.userId !== null,
		isAcesso: state.authReducer.acesso !== null,
		isRegister: state.registerReducer.register
	}
}

const mapDispatchtoProps = dispatch => {
	return {
        onGetEstados: ()=> dispatch(actionCreators.getEstados())
	}
};

export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(FirebaseAux));
