import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    estados: [],
    estadoEdit: [],
    error: null,
    loading: false,
    add: false
}

const clearEstados = (state, action) => {
    const exit = {
        estados: [],
        estadoEdit: [],
        error: null,
        loading: false,
        add: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SET_ESTADOS_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_ESTADOS_SUCCESS: 
            return updateObject(state, {estados: action.estados, loading: false});

        case actionTypes.SET_ESTADOS_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_ESTADOS: 
            return clearEstados(state, action);   

        default:
            return state;
    }
}

export default reducer;