import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    atendimento: null,
    successAtendimento: null,
    successPag: null,
    error: null,
    loading: false,
};

const comprarAtendimentoStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null,
        successAtendimento: null
    }
    return updateObject(state, updateState);
}

const comprarAtendimentoSuccess = (state, action) => {
    const updateState = {
        atendimento: action.atendimento,
        loading: false,
        error: null,
        successAtendimento: true
    }
    return updateObject(state, updateState);
}

const comprarAtendimentoFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error.code){
        case 'auth/user-not-found':
            errorMsg = 'O E-mail não cadastrado';
            break;
        default:
            errorMsg = 'Error para criar uma conta por favor entrar em contato com assistência.'
    }
    const updateState = {
        loading: false,
        error: errorMsg,
        successAtendimento: null
    }
    return updateObject(state, updateState);
}

const comprarPagamentoStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null,
        successPag: null
    }
    return updateObject(state, updateState);
}

const comprarPagamentoStartSuccess = (state, action) => {
    const updateState = {
        loading: false,
        error: null,
        successPag: null
    }
    return updateObject(state, updateState);
}

const comprarPagamentoSuccess = (state, action) => {
    const updateState = {
        loading: false,
        error: null,
        successPag: true
    }
    return updateObject(state, updateState);
}
/*
*/
const comprarPagamentoFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error){
        case '5003':
            errorMsg = 'Falha de comunicação com a instituição financeira';
            break;
        case '10000':
            errorMsg = 'Bandeira do cartão de crédito inválida';
            break;
        case '10001':
            errorMsg = 'Numero do cartão com tamanho inválido';
            break;
        case '10002':
            errorMsg = 'Formato de data inválido';
            break;
        case '10003':
            errorMsg = 'Campo de segurança inválido.';
            break;
        case '10004':
            errorMsg = 'CVV é obrigatório.';
            break;
        case '10006':
            errorMsg = 'Campo de segurança com comprimento inválido.';
            break;
        case '30400':
            errorMsg = 'Dado(s) do cartão de crédito inválido(s)';
            break;
        case '53004':
            errorMsg =	'Quantidade inválida de itens.'
            break;
        case '53005':
            errorMsg =	'Moeda é necessária.'
            break;
        case '53006':
            errorMsg =	'Valor inválido da moeda.'
            break;
        case '53007':
            errorMsg =	'Comprimento inválido de referência.'
            break;
        case '53008':
            errorMsg =	'notificationURL com tamanho inválido.'
            break;
        case '53009':
            errorMsg =	'notificationURL com valor inválido.'
            break;
        case '53010':
            errorMsg =	'email do remetente é obrigatório.'
            break;
        case '53011':
            errorMsg =	'email do remetente com tamanho inválido.'
            break;
        case '53012':
            errorMsg =	'email do remetente com valor inválido.'
            break;
        case '53013':
            errorMsg =	'Nome do remetente é obrigatório.'
            break;
        case '53014':
            errorMsg =	'Nome do remetente com tamanho inválido.'
            break;
        case '53015':
            errorMsg =	'Nome do remetente com valor inválido.'
            break;
        case '53017':
            errorMsg =	'CPF do remetente com valor inválido.'
            break;
        case '53018':
            errorMsg =	'Código de área do remetente é obrigatório.'
            break;
        case '53019':
            errorMsg =	'Código de área do remetente com valor inválido.'
            break;
        case '53020':
            errorMsg =	'Telefone remetente é obrigatório.'
            break;
        case '53021':
            errorMsg =	'Telefone remetente com valor inválido.'
            break;
        case '53022':
            errorMsg =	'Código postal do endereço de entrega é obrigatório.'
            break;
        case '53023':
            errorMsg =	'Código postal do endereço de entrega com valor inválido.'
            break;
        case '53024':
            errorMsg =	'Rua do endereço de entrega é obrigatório.'
            break;
        case '53025':
            errorMsg =	'Rua do endereço de entrega com tamanho inválido.'
            break;
        case '53026':
            errorMsg =	'Número do endereço de entrega é obrigatório.'
            break;
        case '53027':
            errorMsg =	'Número do endereço de entrega com tamanho inválido.'
            break;
        case '53028':
            errorMsg =	'Complemento do endereço de entrega com tamanho inválido.'
            break;
        case '53029':
            errorMsg =	'Bairro de endereço de entrega é obrigatório.'
            break;
        case '53030':
            errorMsg =	'Bairro de endereço de entrega com tamanho inválido.'
            break;
        case '53031':
            errorMsg =	'Cidade de endereço de entrega é obrigatório.'
            break;
        case '53032':
            errorMsg =	'Cidade de endereço de entrega com tamanho inválido.'
            break;
        case '53033':
            errorMsg =	'Estado do endereço de entrega é obrigatório.'
            break;
        case '53034':
            errorMsg =	'Estado do endereço de entrega com valor inválido.'
            break;
        case '53035':
            errorMsg =	'País do endereço de entrega é obrigatório.'
            break;
        case '53036':
            errorMsg =	'País do endereço de entrega com tamanho inválido.'
            break;
        case '53037':
            errorMsg =	'Token do cartão de credito é obrigatório.'
            break;
        case '53038':
            errorMsg =	'Quantidade da parcela é obrigatório.'
            break;
        case '53039':
            errorMsg =	'Quantidade da parcela com valor inválido.'
            break;
        case '53040':
            errorMsg =	'Valor da parcela é obrigatório.'
            break;
        case '53041':
            errorMsg =	'Valor da parcela com valor inválido.'
            break;
        case '53042':
            errorMsg =	'titular do cartão de crédito nome é obrigatório.'
            break;
        case '53043':
            errorMsg =	'Nome do titular do cartão de crédito com tamanho inválido.'
            break;
        case '53044':
            errorMsg =	'Nome do titular do cartão de crédito com valor inválido.'
            break;
        case '53045':
            errorMsg =	'CPF do titular do cartão de crédito é obrigatório.'
            break;
        case '53046':
            errorMsg =	'CPF do titular do cartão de crédito com valor inválido.'
            break;
        case '53047':
            errorMsg =	'Data de nascimento do titular do cartão de crédito é obrigatório.'
            break;
        case '53048':
            errorMsg =	'Data de nascimento do titular do cartão de crédito com valor inválido.'
            break;
        case '53049':
            errorMsg =	'Código de área do do titular do cartão de crédito é obrigatório.'
            break;
        case '53050':
            errorMsg =	'Código de área do do titular do cartão de crédito com valor inválido.'
            break;
        case '53051':
            errorMsg =	'Telefone do titular do cartão de crédito é obrigatório.'
            break;
        case '53052':
            errorMsg =	'Telefone do titular do cartão de crédito com valor inválido.'
            break;
        case '53053':
            errorMsg =	'CEP do endereço de cobrança é obrigatório.'
            break;
        case '53054':
            errorMsg =	'CEP do endereço de cobrança com valor inválido.'
            break;
        case '53055':
            errorMsg =	'Rua do endereço de cobrança é obrigatório.'
            break;
        case '53056':
            errorMsg =	'Rua do endereço de cobrança com tamanho inválido.'
            break;
        case '53057':
            errorMsg =	'Numero do endereço de cobrança é obrigatório.'
            break;
        case '53058':
            errorMsg =	'Numero do endereço de cobrança com tamanho inválido.'
            break;
        case '53059':
            errorMsg =	'Complemento do endereço de cobrança com tamanho inválido.'
            break;
        case '53060':
            errorMsg =	'Bairro do endereço de cobrança é obrigatório.'
            break;
        case '53061':
            errorMsg =	'Bairro do endereço de cobrança com tamanho inválido.'
            break;
        case '53062':
            errorMsg =	'Cidade do endereço de cobrança é obrigatório.'
            break;
        case '53063':
            errorMsg =	'Cidade do endereço de cobrança com tamanho inválido.'
            break;
        case '53064':
            errorMsg =	'Estado do endereço de cobrança é obrigatório.'
            break;
        case '53065':
            errorMsg =	'Estado do endereço de cobrança com valor inválido.'
            break;
        case '53066':
            errorMsg =	'País do endereço de cobrança é obrigatório.'
            break;
        case '53067':
            errorMsg =	'País do endereço de cobrança com tamanho inválido.'
            break;
        case '53068':
            errorMsg =	'Email do destinatário com tamanho inválido.'
            break;
        case '53069':
            errorMsg =	'Email do destinatário com valor inválido.'
            break;
        case '53070':
            errorMsg =	'item id é obrigatório.'
            break;
        case '53071':
            errorMsg =	'item id com tamanho inválido.'
            break;
        case '53072':
            errorMsg =	'Descrição do item é obrigatório.'
            break;
        case '53073':
            errorMsg = 'Descrição do item com tamanho inválido.';
            break;
        case '53074':
            errorMsg = 'Quantidade do item é obrigatório.';
            break;
        case '53075':
            errorMsg = 'Quantidade do item fora da faixa.';
            break;
        case '53076':
            errorMsg = 'Quantidade do item com valor inválido.';
            break;
        case '53077':
            errorMsg = 'Valor do item é obrigatório.';
            break;
        case '53078':
            errorMsg = 'Padrão inválido do valor do item.';
            break;
        case '53079':
            errorMsg = 'Valor do item fora da faixa';
            break;
        case '53081':
            errorMsg = 'Remetente está relacionado ao receptor.';
            break;
        case '53084':
            errorMsg = 'Receptor inválido: verificar o status da conta do destinatário e se é uma conta de vendedor.';
            break;
        case '53085':
            errorMsg = 'Método de pagamento indisponível.';
            break;
        case '53086':
            errorMsg = 'Valor total fora da faixa';
            break;
        case '53087':
            errorMsg = 'Dados de cartão de crédito inválidos.';
            break;
        case '53091':
            errorMsg = 'hash do remetente inválido.';
            break;
        case '53092':
            errorMsg = 'A marca do cartão de crédito não é aceita.';
            break;
        case '53095':
            errorMsg = 'Padrão inválido do tipo de remessa.';
            break;
        case '53096':
            errorMsg = 'Padrão inválido de custos de envio.';
            break;
        case '53097':
            errorMsg = 'Frete fora da faixa';
            break;
        case '53098':
            errorMsg = 'O valor total do carrinho é negativo';
            break;
        case '53099':
            errorMsg = 'Valor inválido de valor extra.';
            break;
        case '53101':
            errorMsg = 'Valor inválido do modo de pagamento, valores válidos são padrão e gateway.';
            break;
        case '53102':
            errorMsg =  'Valor inválido da forma de pagamento, os valores válidos são creditCard, boleto e eft.';
            break;
        case '53104':
            errorMsg =  'Frete foi fornecido, o endereço de entrega deve estar completo.';
            break;
        case '53105':
            errorMsg =  'Informações do remetente foram fornecidas, o email também deve ser fornecido.';
            break;
        case '53106':
            errorMsg =  'Dados do titular do cartão de crédito está incompleto.';
            break;
        case '53109':
            errorMsg =  'Como foram fornecidas as informações do endereço de entrega, o email do remetente também deve ser fornecido.';
            break;
        case '53110':
            errorMsg =  'eft bank é obrigatório.';
            break;
        case '53111':
            errorMsg =  'eft bank is not accepted.';
            break;
        case '53115':
            errorMsg =  'Data de nascimento do remetente com valor inválido.';
            break;
        case '53117':
            errorMsg =  'Remetente cnpj com valor inválido.';
            break;
        case '53122':
            errorMsg =  'Email do domínio remoto inválido. Você deve usar um email @ sandbox.pagseguro.com.br';
            break;
        case '53140':
            errorMsg =  'Quantidade da parcela para a faixa. O valor deve ser maior que zero.';
            break;
        case '53141':
            errorMsg =  'Remetente está bloqueado.';
            break;
        case '53142':
            errorMsg =  'Token de cartão de crédito inválido.';
            break;
        default:
            errorMsg = 'Error para criar uma conta por favor entrar em contato com assistência.'
    }
    const updateState = {
        loading: false,
        error: errorMsg,
        successPag: null
    }
    return updateObject(state, updateState);
}

const comprarClean = (state, action) => {
    const updateState = {
        atendimento: null,
        successAtendimento: null,
        successPag: null,
        error: null,
        loading: false,
    }
    return updateObject(state, updateState);
}
//GET ATENDIMENTO
const setComprarAtendimentoStart = (state, action) => {
    const updateState = {
        atendimento: null,
        loading: true,
        error: null,
        successAtendimento: null,
        successPag: null,
    }
    return updateObject(state, updateState);
}

const setComprarAtendimentoSuccess = (state, action) => {
    const updateState = {
        atendimento: action.atendimento,
        loading: false,
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPRAR_ATENDIMENTO_START:
            return comprarAtendimentoStart(state, action);
        case actionTypes.COMPRAR_ATENDIMENTO_SUCCESS:
            return comprarAtendimentoSuccess(state, action);
        case actionTypes.COMPRAR_ATENDIMENTO_FAIL:
            return comprarAtendimentoFail(state, action);

        case actionTypes.COMPRAR_PAGAMENTO_SUCCESS_START:
            return comprarPagamentoStartSuccess(state, action);

        case actionTypes.COMPRAR_PAGAMENTO_START:
            return comprarPagamentoStart(state, action);
        case actionTypes.COMPRAR_PAGAMENTO_SUCCESS:
            return comprarPagamentoSuccess(state, action);
        case actionTypes.COMPRAR_PAGAMENTO_FAIL:
            return comprarPagamentoFail(state, action);
        //GET
        case actionTypes.SET_COMPRAR_ATENDIMENTO_START:
            return setComprarAtendimentoStart(state, action);
        case actionTypes.SET_COMPRAR_ATENDIMENTO_SUCCESS:
            return setComprarAtendimentoSuccess(state, action);

        case actionTypes.COMPRAR_CLEAN:
            return comprarClean(state, action);
        default:
            return state
    }
}

export default reducer;