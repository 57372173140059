import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeVcard = () => {};
let unsubscribeVcardAtendimento = () => {};
let unsubscribeVcards = [];
let unsubscribeVcardUser = () => {};

//ADICIONAR VCARDS
export const addVcardStart = () => {
    return {
        type: actionTypes.ADD_VCARD_START
    }
}

export const addVcard = (atendimentoId, vcard, item, userId, donoId, changeUrlZappsite = null) => {
    return dispatch =>{
        dispatch(addVcardStart());

        vcard.status = 'criado';
        vcard.atendimentoId = atendimentoId;
        vcard.desativado = false;
        vcard.dono = donoId;
        vcard.item = item;

        FireBase.db.collection('vcards')
        .where('desativado', '==', false)
        .where('urlZapsite', '==', vcard.urlZapsite)
        .get()
        .then((doc) => {
            if (doc.size > 0 && changeUrlZappsite) {
                dispatch(addVcardError('URL zapsite já esta sendo utilizada!'));
                return null;
            }else{
                if(vcard.id){
                    delete vcard.status;
                    FireBase.db.collection('vcards')
                        .doc(vcard.id)
                        .set(   vcard ,
                                {merge: true}
                            )
                        .then(res=>{
                            dispatch(addVcardSuccess());
                        },
                        err=>{
                            
                        })
                }else{
                    FireBase.db.collection('vcards')
                        .add({...vcard, ...{created: FireBase.timeStamp(), userIdCreate: userId}})
                        .then(res=>{
                            dispatch(addVcardSuccess());
                        },
                        err=>{
                            
                        })
                }
            }
            
        });
    }  
}

export const addVcardSuccess = () => {
    return {
        type: actionTypes.ADD_VCARD_SUCCESS
    }
}

export const addVcardError = (errorMsg) => {
    return {
        type: actionTypes.ADD_VCARD_ERROR,
        error: errorMsg
    }
}

//ANEXAR VCARDS
export const anexarVcardStart = () => {
    return {
        type: actionTypes.ANEXAR_VCARD_START
    }
}

export const anexarVcard = (vcardId, anexos, userId) => {
    return dispatch =>{
        dispatch(anexarVcardStart());

        Object.keys(anexos).forEach(res=>{
            console.log(anexos[res])
            console.log(anexos[res].name.split('.'))
            const auxFileName = anexos[res].name.split('.');
            const auxExtension = auxFileName[auxFileName.length-1];
            console.log(auxExtension)
            //const child = `${res}__${vcardId}.${auxExtension}`;
            const child = `${anexos[res].name}`;
            
            const path = `${userId}/vcard/${child}`;
            const uploadTask = FireBase.storage.ref(path).put(anexos[res]);

            const startRefGet = FireBase.storage.ref(`${userId}/vcard/`);
                    
            uploadTask.on('state_changed', 
            (snapshot) => {
                // progrss function ....
                
            }, 
            (error) => {
                // error function ....
                
            }, 
            () => {
                startRefGet
                    .child(`${child}`)
                    .getDownloadURL()
                    .then(url=>{
                        const anexo = {};
                        anexo[res] = {path: path, url: url, name: anexos[res].name};
                        //complete function ....
                        FireBase.db.collection('vcards')
                            .doc(vcardId)
                            .set(   {anexo} ,
                                    {merge: true}
                                )
                            .then(res=>{
                                dispatch(anexarVcardSuccess());
                            },
                            err=>{
                                
                            });
                    });
            });
        });
    }  
}

export const anexarVcardSuccess = () => {
    return {
        type: actionTypes.ANEXAR_VCARD_SUCCESS
    }
}
//DELETAR ANEXO VCARD
export const deleteAnexoVcardStart = () => {
    return {
        type: actionTypes.DELETE_ANEXO_VCARD_START
    }
}

export const deleteAnexoVcard = (vcardId, anexo, item, userId) => {
    return dispatch =>{
        dispatch(deleteAnexoVcardStart());
        const storageRef = FireBase.storage.ref();
        var desertRef = storageRef.child(anexo);

        desertRef.delete().then(res=>{
            let auxDeleteField = { 
                [`anexo.${item}.path`]: FireBase.db.FieldValue.delete(),
                [`anexo.${item}.url`]: FireBase.db.FieldValue.delete(),
                [`anexo.${item}.name`]: FireBase.db.FieldValue.delete()
            };
    
            FireBase.db.collection('vcards')
                .doc(vcardId)
                .update(auxDeleteField)
                .then(res=>{
                    auxDeleteField = {  [ `anexo.${item}`]: FireBase.db.FieldValue.delete() };
                    FireBase.db.collection('vcards')
                        .doc(vcardId)
                        .update(auxDeleteField)
                        .then(res=>{
                            dispatch(deleteAnexoVcardSuccess());
                        });
                });  
        },
        err=>{
            if(err.code === 'storage/object-not-found'){
                let auxDeleteField = { 
                    [`anexo.${item}.path`]: FireBase.db.FieldValue.delete(),
                    [`anexo.${item}.url`]: FireBase.db.FieldValue.delete(),
                    [`anexo.${item}.name`]: FireBase.db.FieldValue.delete()
                };
        
                FireBase.db.collection('vcards')
                    .doc(vcardId)
                    .update(auxDeleteField)
                    .then(res=>{
                        auxDeleteField = {  [ `anexo.${item}`]: FireBase.db.FieldValue.delete() };
                        FireBase.db.collection('vcards')
                            .doc(vcardId)
                            .update(auxDeleteField)
                            .then(res=>{
                                dispatch(deleteAnexoVcardSuccess());
                            });
                    }); 
            }
        });
    }  
}

export const deleteAnexoVcardSuccess = () => {
    return {
        type: actionTypes.DELETE_ANEXO_VCARD_SUCCESS
    }
}
//MUDAR STATUS VCARD
export const setVcardStatusStart = () => {
    return {
        type: actionTypes.SET_VCARD_STATUS_START
    }
}

export const setVcardStatus = (vcardId, status, userId) => {
    return dispatch =>{
        dispatch(setVcardStatusStart());
        
        FireBase.db.collection('vcards')
            .doc(vcardId)
            .set(
                {
                    status: status,
                    userIdUpdate: userId, updated: FireBase.timeStamp()
                },
                {merge: true}
            )
            .then(res => {
                dispatch(setVcardStatusSuccess());
            });
    }  
}

export const setVcardStatusSuccess = (atendimento) => {
    return {
        type: actionTypes.SET_VCARD_STATUS_SUCCESS
    }
}
//ADD ANOTACA VCARD
export const addVcardAnotacaoStart = () => {
    return {
        type: actionTypes.ADD_VCARD_ANOTACA0_START
    }
}

export const addVcardAnotacao = (id, userId, vcardAnotacao) => {
    return dispatch =>{
        dispatch(addVcardAnotacaoStart());
        
        const anotacaoAux = {};
        anotacaoAux['anotacao'] = {};
        anotacaoAux['anotacao'][userId] = vcardAnotacao;
        
        FireBase.db.collection('vcards')
            .doc(id)
            .set(
                anotacaoAux,
                {merge: true}
            )
            .then(res => {
                dispatch(addVcardAnotacaoSuccess());
            });
    }  
}

export const addVcardAnotacaoSuccess = () => {
    return {
        type: actionTypes.ADD_VCARD_ANOTACA0_SUCCESS
    }
}

export const addVcardAnotacaoClear = () => {
    return {
        type: actionTypes.ADD_VCARD_ANOTACA0_CLEAR
    }
}
//GET
export const setVcardStart = (vcardId) => {
    return {
        type: actionTypes.SET_VCARD_START,
        vcardId: vcardId
    }
}

export const getVcard = (vcardId = null, urlZapsite = null) => {
    if(urlZapsite && urlZapsite != 'null'){
        console.log('urlZapsite')
        return dispatch =>{
            dispatch(setVcardStart(vcardId));
            unsubscribeVcard = FireBase.db.collection('vcards')
                .where('desativado', '==', false)
                .where('urlZapsite', '==', urlZapsite)
                .onSnapshot(query => {
                    let data = [];
                    let auxVcardId = null;
                    query.forEach(res=>{
                        data = {...res.data(), ...{id: res.id}}
                        auxVcardId = res.id;
                    })
                    
                    //dispatch(getImages(data)); 
                    dispatch(setVcardSuccess(auxVcardId, null, data));
                });
        }
    }

    return dispatch =>{
        dispatch(setVcardStart(vcardId));
        unsubscribeVcard = FireBase.db.collection('vcards')
            .doc(vcardId)
            .onSnapshot(querySnapshot => {
                const data = querySnapshot.data() 
                    && typeof querySnapshot.data() !== 'undefined' 
                    ? {...querySnapshot.data(), ...{id: querySnapshot.id}} : {};
                
                //dispatch(getImages(data)); 
                //console.log(data)
                dispatch(setVcardSuccess(vcardId, null, data));
            });
    }  
}

export const setVcardSuccess = (vcardId, vcards, vcard = null) => {
    return {
        type: actionTypes.SET_VCARD_SUCCESS,
        vcardId: vcardId,
        vcards: vcards,
        vcard: vcard
    }
}
//GET ATENDIMENTO
export const setVcardsAtendimentoStart = () => {
    return {
        type: actionTypes.SET_VCARDS_ATENDIMENTO_START
    }
}

export const getVcardsAtendimento = (atendimentoID) => {
    return dispatch =>{
        dispatch(setVcardStart());
        unsubscribeVcardAtendimento = FireBase.db.collection('vcards')
            .where('atendimentoId', '==', atendimentoID)
            .onSnapshot(querySnapshot => {
                var vcards = [];
                querySnapshot.forEach(function(doc) {
                    const vcardAux = doc.data();
                    vcardAux.id = doc.id;
                    
                    vcards.push(vcardAux);
                });
                dispatch(setVcardsAtendimentoSuccess(vcards));
            });
    }  
}

export const setVcardsAtendimentoSuccess = (vcards) => {
    return {
        type: actionTypes.SET_VCARDS_ATENDIMENTO_SUCCESS,
        vcards: vcards
    }
}
//GET USER
export const setVcardsUserStart = () => {
    return {
        type: actionTypes.SET_VCARDS_USER_START
    }
}

export const getVcardsUser = (userId) => {
    return dispatch =>{
        dispatch(setVcardStart());
        unsubscribeVcardUser = FireBase.db.collection('vcards')
            .where('dono', '==', userId)
            .onSnapshot(querySnapshot => {
                var vcards = [];
                querySnapshot.forEach(function(doc) {
                    const vcardAux = doc.data();
                    vcardAux.id = doc.id;
                    
                    vcards.push(vcardAux);
                });
                dispatch(setVcardsUserSuccess(vcards));
            });
    }  
}

export const setVcardsUserSuccess = (vcards) => {
    return {
        type: actionTypes.SET_VCARDS_USER_SUCCESS,
        vcards: vcards
    }
}
//GET ANOTACAO USER
export const setVcardsAnotacaoUserStart = () => {
    return {
        type: actionTypes.SET_VCARDS_ANOTACAO_USER_START
    }
}

export const getVcardsAnotacaoUser = (userId) => {
    return dispatch =>{
        dispatch(setVcardStart());
        unsubscribeVcardUser = FireBase.db.collection('vcards')
            .where(`anotacao.${userId}`, '>', '')
            .onSnapshot(querySnapshot => {
                var vcards = [];
                querySnapshot.forEach(function(doc) {
                    const vcardAux = doc.data();
                    vcardAux.id = doc.id;
                    
                    vcards.push(vcardAux);
                });
                dispatch(setVcardsAnotacaoUserSuccess(vcards));
            });
    }  
}

export const setVcardsAnotacaoUserSuccess = (vcards) => {
    return {
        type: actionTypes.SET_VCARDS_ANOTACAO_USER_SUCCESS,
        vcards: vcards
    }
}

// GET ALL
export const setVcardsStart = (pagaNumber) => {
    return {
        type: actionTypes.SET_VCARDS_START,
        pagaNumber: pagaNumber
    }
}

export const getVcards = (fav = null, whereAux = null, start = 'A', pageNumber = 0) => {
    return dispatch =>{
        dispatch(setVcardsStart(pageNumber));

        let getAux = FireBase.db.collection('vcards')
                                    .where('desativado', '==', false);
        if(fav){
            getAux = getAux.where(`fav.${fav}`, '==', true);
        }
        
        let whereAuxNome = null;
        if(whereAux){
            Object.keys(whereAux).forEach(res => {
                if(whereAux[res]){
                    if(res !== 'nome'){
                        getAux = getAux.where(`${res}`, '==', whereAux[res]);
                    }else{
                        if(whereAux[res]){
                            whereAuxNome = true;
                        }
                        getAux = getAux
                                .where(`${res}`, '>=', whereAux[res])
                                .where(`${res}`, '<=', whereAux[res]+ '\uf8ff')
                    }
                }
            });
        }

        if(start && !fav){
            getAux = getAux.orderBy("nome").startAfter(start);
            // if(whereAuxNome){
            //     getAux = getAux.startAt(whereAux['nome']).endAt(whereAux['nome']+'\uf8ff');
            // }
            
            if(!whereAuxNome || pageNumber === 0){
                getAux = getAux.startAfter(start);
            }
            
            getAux = getAux.limit(20);
        }

        unsubscribeVcards[pageNumber] = getAux
            .onSnapshot(querySnapshot => {
                let lines = [];
                let index;
                let lastVisible = start;
                const favAux = fav;
                querySnapshot.docChanges().forEach(function(change) {
                    index = null;
                    
                    if (change.type === "added" ) {
                        let d = change.doc.data();
                        d.id = change.doc.id;
                        index = change.newIndex+(20*pageNumber);
                        lines[index] = d;
                    }
                    if (change.type === "modified") {
                        let d = change.doc.data();    
                        d.id = change.doc.id;
                        index = change.newIndex+(20*pageNumber);

                        lastVisible = change.doc.data().nome;
                        lines[index] = d;
                    }
                    if (change.type === "removed" && favAux) {
                        let d = change.doc.data();
                        d.id = change.doc.id;
                        d.remove = true;
                        index = change.oldIndex+(20*pageNumber);
                        lines[index] = d;
                    }

                    lastVisible = change.doc.data().nome;
                });
                
                if(lines.length > 0) {
                    //dispatch(getImages(lines)); 
                    dispatch(setVcardsSuccess(lines, index, lastVisible, pageNumber));
                }else{
                    dispatch(setVcardsNoMoreSuccess()); 
                }
            });
    }  
}

export const setVcardsNoMoreSuccess = () => {
    return {
        type: actionTypes.SET_VCARDS_NO_MORE_SUCCESS
    }
}

export const setVcardsSuccess = (vcards, index = null, lastVisible, pageNumber) => {
    return {
        type: actionTypes.SET_VCARDS_SUCCESS,
        vcards: vcards,
        index: index, 
        lastVisible: lastVisible, 
        pageNumber: pageNumber
    }
}

// GET IMAGES
export const setVcardImageStart = () => {
    return {
        type: actionTypes.SET_VCARD_IMAGE_START
    }
}

export const setVcardImageFinish = () => {
    return {
        type: actionTypes.SET_VCARD_IMAGE_FINISH
    }
}

export const getImages = (vcards) => {
    return dispatch =>{
        const imagesVcardsAux = {};
        vcards.forEach(res=>{
            if(res.anexo && Object.keys(res.anexo).length > 0){
                const storageRef = FireBase.storage.ref(res.dono);

                storageRef.child('vcard/').listAll()
                    .then(resList=>{
                        resList.items.forEach(imageRef => {
                            const imageIndex = imageRef.name.split('__');
                            
                            imageRef.getDownloadURL().then(function(url) {
                                dispatch(setVcardImageStart());
                                const urlAux = {};
                                urlAux[imageIndex[0]] =url
                                imagesVcardsAux[res.id] =  {...imagesVcardsAux[res.id], ...urlAux};
                                dispatch(setVcardImageSuccess(imagesVcardsAux));
                            })
                            .then(()=>{
                                dispatch(setVcardImageFinish());
                            });
                        });
                    })
            }
        });
    }
}

export const setVcardImageSuccess = (imageVcard) => {
    return {
        type: actionTypes.SET_VCARD_IMAGE_SUCCESS,
        imageVcard: imageVcard
    }
}

// FAV VCARD
export const addVcardsFavStart = () => {
    return {
        type: actionTypes.ADD_VCARD_FAV_START
    }
}

export const addVcardsFav = (vcardId, userId, action) => {
    return dispatch =>{
        dispatch(setVcardsStart());

        if(action === 'add'){
            const actionAux =  {fav:{}};
            actionAux.fav[userId] = true;
            FireBase.db.collection('vcards')
                    .doc(vcardId)
                    .set(actionAux , {merge: true})
                    .then(res=>{
                        dispatch(addVcardsFavSuccess());
                    },
                    err=>{
                        
                    });
        }else{
            const actionAux =  {fav:{}};
            actionAux.fav[userId] = false;
            
            FireBase.db.collection('vcards')
                    .doc(vcardId)
                    .update(actionAux)
                    .then(res=>{
                        dispatch(addVcardsFavSuccess());
                    },
                    err=>{
                        
                    });
        }
    } 
}

export const addVcardsFavSuccess = (vcards) => {
    return {
        type: actionTypes.ADD_VCARD_FAV_SUCCESS
    }
}

//FAIL
export const setVcardFail = () => {
    return {
        type: actionTypes.SET_VCARD_FAIL
    }
};

//ADICIONAR BI
export const biVcardStart = () => {
    return {
        type: actionTypes.ADD_BI_VCARD_START
    }
}

export const biVcard = (vcardId, dataBI) => {
    return dispatch =>{
        dispatch(biVcardStart());
        let auxDataBi = {...dataBI}
        auxDataBi['data'] = FireBase.timeStamp();
        if(dataBI.action === 'voto' || dataBI.action === 'enquete' || dataBI.action === 'acesso'){
            FireBase.db.collection('vcards')
                .doc(vcardId)
                .collection('bi')
                .where('userId', '==', dataBI.userId)
                .get()
                .then(docUser=>{
                    if(docUser.size === 0){
                        FireBase.db.collection('vcards')
                            .doc(vcardId)
                            .collection('bi')
                            .where('uidAnonymous', '==', dataBI.uidAnonymous)
                            .get()
                            .then(docAnonymous=>{
                                if(docAnonymous.size === 0){
                                    FireBase.db.collection('vcards')
                                        .doc(vcardId)
                                        .collection('bi')
                                        .add(auxDataBi)
                                        .then(res=>{
                                            dispatch(biVcardSuccess());
                                        })
                                }else{
                                    dispatch(biVcardSuccess());
                                }
                            })
                    }else{
                        dispatch(biVcardSuccess());
                    }
                })
        }else{
            FireBase.db.collection('vcards')
                .doc(vcardId)
                .collection('bi')
                .add(auxDataBi)
                .then(res=>{
                    dispatch(biVcardSuccess());
                })
        }
    }  
}

export const biVcardSuccess = () => {
    return {
        type: actionTypes.ADD_BI_VCARD_SUCCESS
    }
}

//GET BI
export const setBiVcardStart = () => {
    return {
        type: actionTypes.SET_BI_VCARD_START
    }
}

export const getBiVcard = (vcardId, dateInit, dateEnd) => {
    return dispatch =>{
        dispatch(setBiVcardStart());
        console.log(vcardId, dateInit, dateEnd)
        FireBase.db.collection('vcards')
            .doc(vcardId)
            .collection('bi')
            .where('data', '>=', dateInit)
            .where('data', '<=', dateEnd)
            .onSnapshot(querySnapshot => {
                var vcardBi = [];
                querySnapshot.forEach(function(doc) {
                    const vcardAux = doc.data();
                    var storedDate = new Date(vcardAux.data.toDate());
                    vcardAux.data = storedDate.getTime();

                    vcardAux.id = doc.id;
                    
                    vcardBi.push(vcardAux);
                });

                //dispatch(setBiVcardSuccess(vcardBi));

                unsubscribeVcard = FireBase.db.collection('vcards')
                    .doc(vcardId)
                    .onSnapshot(querySnapshot => {
                            const data = querySnapshot.data() 
                            && typeof querySnapshot.data() !== 'undefined' 
                            ? [{...querySnapshot.data(), ...{id: querySnapshot.id}}] : [];
                            
                            const vcardAux = querySnapshot.data();
                            var storedDate = new Date(vcardAux.created.toDate());
                            vcardAux.created = storedDate.getTime();
 
                            dispatch(setBiVcardSuccess(vcardBi, vcardAux));
                    });
            });
    }  
}

export const setBiVcardSuccess = (vcardBi, vcard) => {
    return {
        type: actionTypes.SET_BI_VCARD_SUCCESS,
        vcard: vcard,
        vcardBi: vcardBi,
    }
}

//CLEAN
export const vcardClean = () => {
    return {
        type: actionTypes.VCARD_CLEAN
    }
};

// EXIT ATENDIMENTO
export const exitVcard = () => {
    return dispatch => {
        unsubscribeVcardUser();
        unsubscribeVcardAtendimento();
        unsubscribeVcard();
        unsubscribeVcards.forEach(res=>{
            res();
        });
        dispatch(vcardClean());
    }
}