import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    contato: [],
    error: null,
    loading: false,
    add: false
}

const addContatoSuccess = (state, action) => {
    const newState = {
        contato: action.contato,
        add: true
    }
    return updateObject(state,newState);
}

const addContatoFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const clearContato = (state, action) => {
    const exit = {
        contato: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_CONTATO_START:
            return updateObject(state, {loading: true, add: false, error: null});

        case actionTypes.ADD_CONTATO_SUCCESS:
            return addContatoSuccess(state, action); 

        case actionTypes.ADD_CONTATO_FAIL:
            return addContatoFail(state, action);

        case actionTypes.SET_CONTATO_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_CONTATO_SUCCESS: 
            return updateObject(state, {contato: action.contato, loading: false});

        case actionTypes.SET_CONTATO_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_CONTATO: 
            return clearContato(state, action);   

        default:
            return state;
    }
}

export default reducer;