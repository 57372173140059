import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import configureStore from './utils/configureStore';
/* IMPORTE INICIAL */
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Spinner from './components/UI/Spinner/Spinner';

let {store, persistor} = configureStore();

let app = (
    <Provider store={store}>
        <PersistGate loading={<Spinner/>} persistor={persistor}>
            <BrowserRouter>
                
                <App />
                
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.register();
