import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

import moment from 'moment';

let unsubscribeChat = () => {};
//MODIFICAR
//ADICIONAR CHAT
export const addChatStart = () => {
    return {
        type: actionTypes.ADD_CHAT_START
    }
}

export const addChat = (zappsiteId, msg, userName, userId, dataCreate = null, createChat = null, chatId = null) => {
    return dispatch =>{
        dispatch(addChatStart());
        if(dataCreate){
            dataCreate.zappsiteId = zappsiteId;
            FireBase.db.collection('chats')
                .add(dataCreate)
                .then(res=>{
                    console.log(res)
                    if(createChat){
                        dispatch(addChatSuccess());
                        return;
                    }else{
                        const chat = {};
                        chat.msg = msg;
                        chat.userName = userName;
                        chat.userId = userId;
                        chat.data = FireBase.timeStamp();
                
                        FireBase.db.collection('chats')
                            .doc(res.id)
                            .collection('msgs')
                            .add(chat)
                            .then(res=>{
                                dispatch(addChatSuccess());
                            })
                    }
                })
            
        }else{
            const chat = {};
            chat.msg = msg;
            chat.userName = userName;
            chat.userId = userId;
            chat.data = FireBase.timeStamp();
    
            FireBase.db.collection('chats')
                .doc(chatId)
                .collection('msgs')
                .add(chat)
                .then(res=>{
                    dispatch(addChatSuccess());
                })
        }
    }  
}

export const addChatSuccess = () => {
    return {
        type: actionTypes.ADD_CHAT_SUCCESS
    }
}
//GET ALL CHAT
export const setChatsStart = () => {
    return {
        type: actionTypes.SET_CHATS_START
    }
}

export const getChats = (userId, location = null) => {
    return dispatch =>{
        dispatch(setChatsStart());
        if(!location){
            unsubscribeChat = FireBase.db.collection('chats')
                .where(`dono`, '==', userId)
                .onSnapshot(querySnapshot => {
                    if(querySnapshot.exists){
                        dispatch(setChatsSuccess(querySnapshot.data()));
                    }
                });
        }else{
            unsubscribeChat = FireBase.db.collection('chats')
                .where(`${location}`, '==', userId)
                .onSnapshot(querySnapshot => {
                    if(querySnapshot.size > 0){
                        const dataAux = querySnapshot.docs.map(res=>{
                            return {...{id: res.id},...res.data()};
                        })
                        
                        dispatch(setChatsSuccess(dataAux));
                    }
                });
        }
    }  
}

export const setChatsSuccess = (chats) => {
    return {
        type: actionTypes.SET_CHATS_SUCCESS,
        chats: chats
    }
}
//MODIFICAR
//GET CHAT
export const setChatStart = (oldMsg) => {
    return {
        type: actionTypes.SET_CHAT_START,
        oldMsg: oldMsg
    }
}

export const getChat = (zappsiteId, dono, convidado) => {

    return dispatch =>{
        dispatch(setChatStart());

        unsubscribeChat = FireBase.db.collection('chats')
            .where('zappsiteId', '==', zappsiteId)
            .where(`convidado`, '==', convidado)
            .where(`dono`, '==', dono)
            .onSnapshot(querySnapshot => {
                if(querySnapshot.docs.length > 0){
                    let chatAux = querySnapshot.docs[0].data();
                    chatAux.id = querySnapshot.docs[0].id
                    dispatch(setChatSuccess(chatAux));
                }
            });
    }  
}

export const setChatSuccess = (chat) => {
    return {
        type: actionTypes.SET_CHAT_SUCCESS,
        chat: chat
    }
}
//MODIFICAR
//GET CHAT MESSAGES
export const setChatMsgStart = (oldMsg) => {
    return {
        type: actionTypes.SET_CHAT_MSG_START,
        oldMsg: oldMsg
    }
}

export const getChatMsg = (chatId, oldMsg = null) => {
    return dispatch =>{
        dispatch(setChatMsgStart());
        console.log(chatId, oldMsg)
        if(oldMsg){
            FireBase.db.collection('chats')
                .doc(chatId)
                .collection('msgs')
                .where('data', '<', new Date(Date.now() - 72000000))
                .orderBy("data", 'desc')
                .limit(10)
                .get()
                .then(querySnapshot => {
                    var msgs = [];
                    querySnapshot.forEach(function(doc) {
                        var aux = doc.data();
                        var storedDate = new Date(aux.data.toDate());
                        aux.data = storedDate.getTime();

                        msgs.push(aux);
                    });
                    console.log(msgs)
                    dispatch(setChatMsgSuccess(null, msgs));
                })
        }else{
            unsubscribeChat = FireBase.db.collection('chats')
                .doc(chatId)
                .collection('msgs')
                .where('data', '>=', new Date(Date.now() - 72000000))
                .orderBy("data", 'desc')
                .onSnapshot(querySnapshot => {
                    var msgs = [];
                    querySnapshot.forEach(function(doc) {
                        var aux = doc.data();
                        if(aux.data) {
                            var storedDate = new Date(aux.data.toDate());
                            aux.data = storedDate.getTime();
                            
                            msgs.push(aux);
                        }
                    });
                    console.log(msgs, new Date(Date.now() - 240000))
                    dispatch(setChatMsgSuccess(msgs));
                });
        }
    }  
}

export const setChatMsgSuccess = (msgs, oldMsg = null) => {
    return {
        type: actionTypes.SET_CHAT_MSG_SUCCESS,
        msgs: msgs,
        oldMsg: oldMsg
    }
}
//MODIFICAR
//ADICIONAR CHAT VIDEO
export const addChatVideoStart = () => {
    return {
        type: actionTypes.ADD_CHAT_VIDEO_START
    }
}

export const addChatVideo = (chatId, userVideo) => {
    return dispatch =>{
        dispatch(addChatVideoStart());

        FireBase.db.collection('chats')
            .doc(chatId)
            .set(userVideo,
                {merge: true
            })
            .then(res=>{
                dispatch(addChatVideoSuccess(userVideo));
            })
    }  
}

export const addChatVideoSuccess = (userVideo) => {
    return {
        type: actionTypes.ADD_CHAT_VIDEO_SUCCESS,
        userVideo: userVideo
    }
}

//MODIFICAR
//CLEAN VIDEO

export const cleanChatVideoStart = () => {
    return {
        type: actionTypes.CLEAN_CHAT_VIDEO_START
    }
}

export const cleanChatVideo = (chatId, data) => {
    return dispatch =>{
        dispatch(cleanChatVideoStart());

        FireBase.db.collection('chats')
            .doc(chatId)
            .update(data)
            .then(res=>{
                dispatch(cleanChatVideoSuccess());
            })
    }  
}

export const cleanChatVideoSuccess = () => {
    return {
        type: actionTypes.CLEAN_CHAT_VIDEO_SUCCESS
    }
}

//CLEAN
export const chatClean = () => {
    return {
        type: actionTypes.CHAT_CLEAN
    }
};

// EXIT ATENDIMENTO
export const exitChat = () => {
    return dispatch => {
        unsubscribeChat();
        dispatch(chatClean());
    }
}
//MODIFICAR
//ADICIONAR ANEXO
export const addChatAnexoStart = () => {
    return {
        type: actionTypes.ADD_CHAT_ANEXO_START
    }
}

export const addChatAnexo = (zappsiteId, msg, userName, userId, anexos, dataCreate = null, chatId = null) => {
    return dispatch =>{
        dispatch(addChatAnexoStart());
        if(dataCreate){
            dataCreate.zappsiteId = zappsiteId;
            FireBase.db.collection('chats')
                .add(dataCreate)
                .then(response=>{
                    Object.keys(anexos).forEach(res=>{
                        var dataAux = moment(new Date()).format('DD/MM/YYYY-hh:mm');
                        const path = `chat/${zappsiteId}/${res}__${userId}__${dataAux}`;
                        const uploadTask = FireBase.storage.ref(path).put(anexos[res]);

                        const startRefGet = FireBase.storage.ref(`chat/${zappsiteId}/`);

                        uploadTask.on('state_changed', 
                        (snapshot) => {}, 
                        (error) => {}, 
                        () => {
                            startRefGet
                                .child(`${res}__${userId}__${dataAux}`)
                                .getDownloadURL()
                                .then(url=>{
                                    const chat = {};
                                    chat.msg = msg;
                                    chat.userName = userName;
                                    chat.userId = userId;
                                    chat.anexo = true;
                                    chat.data = FireBase.timeStamp();
                                    chat.path = path;
                                    chat.url = url;
                            
                                    FireBase.db.collection('chats')
                                        .doc(response.id)
                                        .collection('msgs')
                                        .add(chat)
                                        .then(res=>{
                                            dispatch(addChatAnexoSuccess());
                                        })
                            
                                    FireBase.db.collection('chats')
                                        .doc(response.id)
                                        .collection('anexos')
                                        .add({
                                            path: path,
                                            url: url,
                                            userId: userId,
                                            dataCreate: FireBase.timeStamp()
                                        })
                                        .then(res=>{
                                            dispatch(addChatAnexoSuccess());
                                        })
                                });
                        });
                    });
                })
            
        }else{   
            Object.keys(anexos).forEach(res=>{
                var dataAux = moment(new Date()).format('DD/MM/YYYY-hh:mm');
                const path = `chat/${zappsiteId}/${res}__${userId}__${dataAux}`;
                
                const uploadTask = FireBase.storage.ref(path).put(anexos[res]);

                const startRefGet = FireBase.storage.ref(`chat/${zappsiteId}/`);

                uploadTask.on('state_changed', 
                (snapshot) => {}, 
                (error) => {
                    console.log(error)
                }, 
                () => {
                    startRefGet
                        .child(`${res}__${userId}__${dataAux}`)
                        .getDownloadURL()
                        .then(url=>{
                            const chat = {};
                            chat.msg = msg;
                            chat.userName = userName;
                            chat.userId = userId;
                            chat.anexo = true;
                            chat.data = FireBase.timeStamp();
                            chat.path = url;
                    
                            FireBase.db.collection('chats')
                                .doc(chatId)
                                .collection('msgs')
                                .add(chat)
                                .then(res=>{
                                    dispatch(addChatAnexoSuccess());
                                })
                            
                            FireBase.db.collection('chats')
                                .doc(chatId)
                                .collection('anexos')
                                .add({
                                    path: path,
                                    url: url,
                                    userId: userId,
                                    dataCreate: FireBase.timeStamp()
                                })
                                .then(res=>{
                                    dispatch(addChatAnexoSuccess());
                                })
                        });
                });
            });
        }
    }  
}

export const addChatAnexoSuccess = () => {
    return {
        type: actionTypes.ADD_CHAT_ANEXO_SUCCESS
    }
}