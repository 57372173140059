import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../util';

const initialState = {
    vcardId: null,
    vcards: null,
    vcard: null,
    vcardBi: null,
    lastVisible: null,
    pageNumber: 0, 
    categoria: null,
    imagesVcards: {},
    loadingImages: null,
    success: null,
    error: null,
    loading: false,
    loadingGet: false,

    successAnotacao: null,
    successGetBi: null,
};
//ADD BI
const addBiVcardStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const addBiVcardSuccess = (state, action) => {
    const updateState = {
        loading: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}
//ADD
const addVcardStart = (state, action) => {
    const updateState = {
        loading: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const addVcardSuccess = (state, action) => {
    const updateState = {
        loading: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}

const addVcardError = (state, action) => {
    const updateState = {
        loading: false,
        error: action.error,
        success: null
    }
    return updateObject(state, updateState);
}

//GET
const setVcardStart = (state, action) => {
    const updateState = {
        vcardId: null,
        vcard: null,
        loadingGet: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const setVcardSuccess = (state, action) => {
    const updateState = {
        vcardId: action.vcardId,
        vcards: action.vcards,
        vcard: action.vcard,
        loadingGet: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}

// GET IMAGE
const setVcardImageSuccess = (state, action) => {
    const updateState = {
        imagesVcards: {...state.imagesVcards, ...action.imageVcard}
    }
    
    return updateObject(state, updateState);
}

//GET ATENDIMENTO
const setVcardsAtendimentoStart = (state, action) => {
    const updateState = {
        vcardId: null,
        vcard: null,
        loadingGet: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const setVcardsAtendimentoSuccess = (state, action) => {
    const updateState = {
        vcards: action.vcards,
        loadingGet: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}
//GET USER
const setVcardsAnotacaoUserStart = (state, action) => {
    const updateState = {
        vcardId: null,
        vcard: null,
        loadingGet: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const setVcardsAnotacaoUserSuccess = (state, action) => {
    const updateState = {
        vcards: action.vcards,
        loadingGet: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}
//GET USER
const setVcardsUserStart = (state, action) => {
    const updateState = {
        vcardId: null,
        vcard: null,
        loadingGet: true,
        error: null,
        success: null
    }
    return updateObject(state, updateState);
}

const setVcardsUserSuccess = (state, action) => {
    const updateState = {
        vcards: action.vcards,
        loadingGet: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}
//GET ALL
const setVcardsStart = (state, action) => {
    let vcardAux = {};
    
    if(action.pagaNumber === 0){
        vcardAux.vcards = null;
    }

    const updateState = {
        loadingGet: true,
        error: null,
        success: null
    }

    return updateObject(state, {...updateState, ...vcardAux});
}

const setVcardsSuccess = (state, action) => {
    let vcardsAux;
    let lastVisibleAux = action.lastVisible;

    if(action.index !== null && state.vcards){
        const stateAux = [...state.vcards];
        action.vcards.forEach((res, i)=>{
            if(res.remove){
                stateAux.splice(i,1)
            }else{
                stateAux[i] = res;
            }
        });
        vcardsAux=stateAux;
        
        lastVisibleAux = vcardsAux.length > 0 ? vcardsAux[vcardsAux.length-1].nome : 'A';
    }else{
        vcardsAux=action.vcards;
    }
    
    if(!state.lastVisible){

    }

    const updateState = {
        vcards: vcardsAux,
        lastVisible: lastVisibleAux,
        pageNumber: action.pageNumber,
        loadingGet: false,
        error: null,
        success: true
    }
    return updateObject(state, updateState);
}
//CLEAN
const vcardClean = (state, action) => {
    const updateState = {
        vcardId: null,
        vcards: null,
        vcard: null,
        vcardBi: null,
        lastVisible: null,
        pageNumber: 0, 
        categoria: null,
        imagesVcards: {},
        loadingImages: null,
        success: null,
        error: null,
        loading: false,
        loadingGet: false,
    
        successAnotacao: null,
    }
    return updateObject(state, updateState);
}
//FAIL
const setVcardFail = (state, action) => {
    
    let errorMsg = '';
    
    switch(action.error.code){
        default:
            errorMsg = 'Error para criar uma conta por favor entrar em contato com assistência.'
    }
    const updateState = {
        loading: false,
        loadingGet: false,
        error: errorMsg,
        success: null
    }
    return updateObject(state, updateState);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //ADICIONAR BI
        case actionTypes.ADD_BI_VCARD_START:
            return addBiVcardStart(state, action);
        case actionTypes.ADD_BI_VCARD_SUCCESS:
            return addBiVcardSuccess(state, action);
        //ADICIONAR VCARDS
        case actionTypes.ADD_VCARD_START:
            return addVcardStart(state, action);
        case actionTypes.ADD_VCARD_SUCCESS:
            return addVcardSuccess(state, action);
        case actionTypes.ADD_VCARD_ERROR:
            return addVcardError(state, action);
        //GET VCARDS
        case actionTypes.SET_VCARD_START:
            return setVcardStart(state, action);
        case actionTypes.SET_VCARD_SUCCESS:
            return setVcardSuccess(state, action);
        //GET VCARDS ATENDIMENTO
        case actionTypes.SET_VCARDS_ATENDIMENTO_START:
            return setVcardsAtendimentoStart(state, action);
        case actionTypes.SET_VCARDS_ATENDIMENTO_SUCCESS:
            return setVcardsAtendimentoSuccess(state, action);
        //GET VCARDS USER
        case actionTypes.SET_VCARDS_USER_START:
            return setVcardsUserStart(state, action);
        case actionTypes.SET_VCARDS_USER_SUCCESS:
            return setVcardsUserSuccess(state, action);
        //GET VCARDS ANOTACAO USER
        case actionTypes.SET_VCARDS_ANOTACAO_USER_START:
            return setVcardsAnotacaoUserStart(state, action);
        case actionTypes.SET_VCARDS_ANOTACAO_USER_SUCCESS:
            return setVcardsAnotacaoUserSuccess(state, action);
        //GET VCARDS 
        case actionTypes.SET_VCARDS_START:
            return setVcardsStart(state, action);
        case actionTypes.SET_VCARDS_SUCCESS:
            return setVcardsSuccess(state, action);
        case actionTypes.SET_VCARDS_NO_MORE_SUCCESS:
            return updateObject(state, {success: true, error: null,loadingGet: false});
        //FAV VCARDS
        case actionTypes.ADD_VCARD_FAV_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.ADD_VCARD_FAV_SUCCESS:
            return updateObject(state, {success: null, error: null,loading: false});
        
        //ANEXAR VCARDS
        case actionTypes.ANEXAR_VCARD_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.ANEXAR_VCARD_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        //DETELE ANEXO VCARDS
        case actionTypes.DELETE_ANEXO_VCARD_START:
            return updateObject(state, {success: null, error: null,loading: true});
        case actionTypes.DELETE_ANEXO_VCARD_SUCCESS:
            return updateObject(state, {success: true, error: null,loading: false});

        //MUDAR STATUS VCARD
        case actionTypes.SET_VCARD_STATUS_START:
            return updateObject(state, {success: null, error: null,loading: false});
        case actionTypes.SET_VCARD_STATUS_SUCCESS:
            return updateObject(state, {success: null, error: null,loading: false});
        //ADD ANOTACA VCARD
        case actionTypes.ADD_VCARD_ANOTACA0_START:
            return updateObject(state, {successAnotacao: null, error: null,loading: false});
        case actionTypes.ADD_VCARD_ANOTACA0_SUCCESS:
            return updateObject(state, {successAnotacao: true, error: null,loading: false});
        case actionTypes.ADD_VCARD_ANOTACA0_CLEAR:
            return updateObject(state, {successAnotacao: null, error: null,loading: false});
        //GET IMAGE
        case actionTypes.SET_VCARD_IMAGE_SUCCESS:
            return setVcardImageSuccess(state, action);
        case actionTypes.SET_VCARD_IMAGE_START:
            return updateObject(state, {loadingImages: true});
        case actionTypes.SET_VCARD_IMAGE_FINISH:
            return updateObject(state, {loadingImages: false});
        
        //GET BI VCARD
        case actionTypes.SET_BI_VCARD_START:
            return updateObject(state, {successGetBi: null, loading: true});
        case actionTypes.SET_BI_VCARD_SUCCESS:
            return updateObject(state, {vcard: action.vcard, vcardBi: action.vcardBi, successGetBi: true, loading: false});
             
        case actionTypes.SET_VCARD_FAIL:
            return setVcardFail(state, action);
        case actionTypes.VCARD_CLEAN:
            return vcardClean(state, action);
        default:
            return state
    }
}

export default reducer;