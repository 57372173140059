import * as actionTypes from '../actions/actionTypes';
import { updateObject }  from '../util';

const initialState = {
    funcoes: [],
    funcaoEdit: [],
    error: null,
    loading: false,
    add: false
}

const deleteFuncaoSuccess = (state, action) => {
    const newState = {
        loading: false,
        error: null
    }
    return updateObject(state,newState);
}

const deleteFuncaoFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}

const addFuncaoSuccess = (state, action) => {
    const newState = {
        funcoes: action.funcoes,
        funcaoEdit: action.funcoes, 
        add: true
    }
    return updateObject(state,newState);
}

const addFuncaoFail = (state, action) => {
    return updateObject(state,{
        loading: false,
        error: action.error              
    });
}
const clearUnidade = (state, action) => {
    const exit = {
        funcoes: [],
        funcaoEdit: [],
        error: null,
        loading: false,
        add: false,
        delete: false
    }
    return updateObject(state, exit)
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_FUNCAO_START:
            return updateObject(state, {loading: true});

        case actionTypes.DELETE_FUNCAO_SUCCESS:
            return deleteFuncaoSuccess(state, action); 

        case actionTypes.DELETE_FUNCAO_FAIL:
            return deleteFuncaoFail(state, action);

        case actionTypes.ADD_FUNCAO_START:
            return updateObject(state, {loading: true, add: false});

        case actionTypes.ADD_FUNCAO_SUCCESS:
            return addFuncaoSuccess(state, action); 

        case actionTypes.ADD_FUNCAO_FAIL:
            return addFuncaoFail(state, action);

        case actionTypes.SET_FUNCOES_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_FUNCOES_SUCCESS: 
            return updateObject(state, {funcoes: action.funcoes, loading: false});

        case actionTypes.SET_FUNCOES_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.SET_FUNCAO_START: 
            return updateObject(state, {loading: true, add: false});

        case actionTypes.SET_FUNCAO_SUCCESS: 
            return updateObject(state, {funcaoEdit: action.funcaoEdit, loading: false});

        case actionTypes.SET_FUNCAO_FAIL: 
            return updateObject(state, {loading: false});

        case actionTypes.EXIT_FUNCOES: 
            return clearUnidade(state, action);   

        default:
            return state;
    }
}

export default reducer;