import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeAtendimento = () => {};
let unsubscribeAtendimentos = () => {};

//MUDAR STATUS ATENDIMENTO
export const setAtendimentoStatusStart = () => {
    return {
        type: actionTypes.SET_ATENDIMENTO_STATUS_START
    }
}

export const setAtendimentoStatus = (id, status, statusName, userId) => {
    return dispatch =>{
        dispatch(setAtendimentoStatusStart());
        
        FireBase.db.collection('atendimentos')
            .doc(id)
            .set(
                {
                    status: status,
                    statusName: statusName,
                    userIdUpdate: userId, updated: FireBase.timeStamp()
                },
                {merge: true}
            )
            .then(res => {
                dispatch(setAtendimentoStatusSuccess());
            });
    }  
}

export const setAtendimentoStatusSuccess = (atendimento) => {
    return {
        type: actionTypes.SET_ATENDIMENTO_STATUS_SUCCESS,
        atendimento: atendimento
    }
}

//GET
export const setAtendimentoStart = () => {
    return {
        type: actionTypes.SET_ATENDIMENTO_START
    }
}

export const getAtendimento = (id) => {
    return dispatch =>{
        dispatch(setAtendimentoStart());
        
        unsubscribeAtendimento = FireBase.db.collection('atendimentos')
            .doc(id)
            .onSnapshot(querySnapshot => {
                dispatch(setAtendimentoSuccess(id, querySnapshot.data()));
            });
    }  
}

export const setAtendimentoSuccess = (id, atendimento) => {
    return {
        type: actionTypes.SET_ATENDIMENTO_SUCCESS,
        atendimento: atendimento,
        atendimentoId: id
    }
}

export const setAtendimentoFail = (error) => {
    return {
        type: actionTypes.SET_ATENDIMENTO_FAIL,
        error: error
    }
}

// GET ALL
export const setAtendimentosStart = () => {
    return {
        type: actionTypes.SET_ATENDIMENTOS_START
    }
}

export const getAtendimentos = (header = null, options = null, userId = null) => {
    return dispatch =>{
        dispatch(setAtendimentosStart());
        let getAux = FireBase.db.collection('atendimentos')
                                                    .orderBy("status", "asc")
                                                    .orderBy("userName", "asc")
                                                    .where('desativado', '==', false);
        if(userId){
           getAux = getAux.where('userId', '==', userId)
        }

        unsubscribeAtendimentos = getAux
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
                querySnapshot.forEach(function(doc) {
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }

                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        d.produto = d.userName ? d.userName : '';
                        d.status = d.statusName;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        d.id = doc.id;
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setAtendimentosSuccess(docAux));
            });
    }  
}

export const setAtendimentosSuccess = (atendimentos) => {
    return {
        type: actionTypes.SET_ATENDIMENTOS_SUCCESS,
        atendimentos: atendimentos
    }
}

//ANEXAR ATENDIMENTO
export const anexarAtendimentoStart = () => {
    return {
        type: actionTypes.ANEXAR_ATENDIMENTO_START
    }
}

export const anexarAtendimento = (atendimentoId, anexos, userId) => {
    return dispatch =>{
        console.log(anexos)
        dispatch(anexarAtendimentoStart());

        Object.keys(anexos).forEach(res=>{
            const auxFileName = anexos[res].name.split('.');
            const auxExtension = auxFileName[auxFileName.length-1];
            
            const path = `${userId}/atendimento/${res}__${atendimentoId}.${auxExtension}`;
            const uploadTask = FireBase.storage.ref(path).put(anexos[res]);
                    
            uploadTask.on('state_changed', 
            (snapshot) => {
                // progrss function ....
                
            }, 
            (error) => {
                // error function ....
                
            }, 
            () => {
                const anexo = {};
                anexo[res] = {path: path};
                //complete function ....
                FireBase.db.collection('atendimentos')
                    .doc(atendimentoId)
                    .set(   {anexo} ,
                            {merge: true}
                        )
                    .then(res=>{
                        dispatch(anexarAtendimentoSuccess());
                    },
                    err=>{
                        
                    });
            });
        });
    }  
}

export const anexarAtendimentoSuccess = () => {
    return {
        type: actionTypes.ANEXAR_ATENDIMENTO_SUCCESS
    }
}

//DELETAR ANEXO ATENDIMENTO
export const deleteAnexoAtendimentoStart = () => {
    return {
        type: actionTypes.DELETE_ANEXO_ATENDIMENTO_START
    }
}

export const deleteAnexoAtendimento = (atendimentoId, anexo, item) => {
    return dispatch =>{
        dispatch(deleteAnexoAtendimentoStart());
        const storageRef = FireBase.storage.ref();
        var desertRef = storageRef.child(anexo);

        desertRef.delete().then(res=>{
            let auxDeleteField = { [`anexo.${item}.path`]: FireBase.db.FieldValue.delete(),  };
    
            FireBase.db.collection('atendimentos')
                .doc(atendimentoId)
                .update(auxDeleteField)
                .then(res=>{
                    let auxDeleteField = { [`anexo.${item}`]: FireBase.db.FieldValue.delete(),  };
            
                    FireBase.db.collection('atendimentos')
                        .doc(atendimentoId)
                        .update(auxDeleteField)
                        .then(res=>{
                            dispatch(deleteAnexoAtendimentoSuccess());
                        }); 
                });  
        });
    }  
}

export const deleteAnexoAtendimentoSuccess = () => {
    return {
        type: actionTypes.DELETE_ANEXO_ATENDIMENTO_SUCCESS
    }
}

//CLEAN
export const atendimentoClean = () => {
    return {
        type: actionTypes.ATENDIMENTO_CLEAN
    }
};

// EXIT ATENDIMENTO
export const exitAtendimento = () => {
    return dispatch => {
        unsubscribeAtendimento();
        unsubscribeAtendimentos();
        dispatch(atendimentoClean());
    }
}