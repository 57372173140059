//AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEANERROR = 'AUTH_CLEANERROR';
export const AUTH_SETACESSO = 'AUTH_SETACESSO';

//REGISTER
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_CLEANERROR = 'REGISTER_CLEANERROR';
export const REGISTER_CLEANSUCCESS = 'REGISTER_CLEANSUCCESS';
export const REGISTER_CLEAN = 'REGISTER_CLEAN';

//FORGOTPASS
export const FORGOTPASS_START = 'FORGOTPASS_START';
export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_FAIL = 'FORGOTPASS_FAIL';
export const FORGOTPASS_CLEANERROR = 'FORGOTPASS_CLEANERROR';
export const FORGOTPASS_CLEANSUCCESS = 'FORGOTPASS_CLEANSUCCESS';

//COMPRAR
export const COMPRAR_ATENDIMENTO_START = 'COMPRAR_ATENDIMENTO_START';
export const COMPRAR_ATENDIMENTO_SUCCESS = 'COMPRAR_ATENDIMENTO_SUCCESS';
export const COMPRAR_ATENDIMENTO_FAIL = 'COMPRAR_ATENDIMENTO_FAIL';
export const COMPRAR_PAGAMENTO_START = 'COMPRAR_PAGAMENTO_START';
export const COMPRAR_PAGAMENTO_SUCCESS_START = 'COMPRAR_PAGAMENTO_SUCCESS_START';
export const COMPRAR_PAGAMENTO_SUCCESS = 'COMPRAR_PAGAMENTO_SUCCESS';
export const COMPRAR_PAGAMENTO_FAIL = 'COMPRAR_PAGAMENTO_FAIL';
export const COMPRAR_CLEANERROR = 'COMPRAR_CLEANERROR';
export const COMPRAR_CLEAN = 'COMPRAR_CLEAN';

export const SET_COMPRAR_ATENDIMENTO_START = 'SET_COMPRAR_ATENDIMENTO_START';
export const SET_COMPRAR_ATENDIMENTO_SUCCESS = 'SET_COMPRAR_ATENDIMENTO_SUCCESS';

//ATENDIMENTOS

export const SET_ATENDIMENTO_START = 'SET_ATENDIMENTO_START';
export const SET_ATENDIMENTO_SUCCESS = 'SET_ATENDIMENTO_SUCCESS';
export const SET_ATENDIMENTO_FAIL = 'SET_ATENDIMENTO_FAIL';

export const SET_ATENDIMENTOS_START = 'SET_ATENDIMENTOS_START';
export const SET_ATENDIMENTOS_SUCCESS = 'SET_ATENDIMENTOS_SUCCESS';

export const SET_ATENDIMENTO_STATUS_START = 'SET_ATENDIMENTO_STATUS_START';
export const SET_ATENDIMENTO_STATUS_SUCCESS = 'SET_ATENDIMENTO_STATUS_SUCCESS';

export const ADD_ATENDIMENTO_DETALHE_START = 'ADD_ATENDIMENTO_DETALHE_START';
export const ADD_ATENDIMENTO_DETALHE_SUCCESS = 'ADD_ATENDIMENTO_DETALHE_SUCCESS';

export const ANEXAR_ATENDIMENTO_START = 'ANEXAR_ATENDIMENTO_START';
export const ANEXAR_ATENDIMENTO_SUCCESS = 'ANEXAR_ATENDIMENTO_SUCCESS';

export const DELETE_ANEXO_ATENDIMENTO_START = 'ANEXAR_ATENDIMENTO_START';
export const DELETE_ANEXO_ATENDIMENTO_SUCCESS = 'ANEXAR_ATENDIMENTO_SUCCESS';

export const EXIT_ATENDIMENTO = 'EXIT_ATENDIMENTO';
export const ATENDIMENTO_CLEAN = 'ATENDIMENTO_CLEAN';

//VCARDS
export const ADD_VCARD_START = 'ADD_VCARD_START';
export const ADD_VCARD_SUCCESS = 'ADD_VCARD_SUCCESS';
export const ADD_VCARD_ERROR = 'ADD_VCARD_ERROR';

export const ADD_VCARD_ANOTACA0_START = 'ADD_VCARD_ANOTACA0_START';
export const ADD_VCARD_ANOTACA0_SUCCESS = 'ADD_VCARD_ANOTACA0_SUCCESS';
export const ADD_VCARD_ANOTACA0_CLEAR = 'ADD_VCARD_ANOTACA0_CLEAR';

export const SET_VCARDS_ANOTACAO_USER_START = 'SET_VCARDS_ANOTACAO_USER_START';
export const SET_VCARDS_ANOTACAO_USER_SUCCESS = 'SET_VCARDS_ANOTACAO_USER_SUCCESS';

export const SET_VCARD_START = 'SET_VCARD_START';
export const SET_VCARD_SUCCESS = 'SET_VCARD_SUCCESS';

export const SET_VCARDS_ATENDIMENTO_START = 'SET_VCARDS_ATENDIMENTO_START';
export const SET_VCARDS_ATENDIMENTO_SUCCESS = 'SET_VCARDS_ATENDIMENTO_SUCCESS';

export const SET_VCARDS_START = 'SET_VCARDS_START';
export const SET_VCARDS_SUCCESS = 'SET_VCARDS_SUCCESS';
export const SET_VCARDS_NO_MORE_SUCCESS = 'SET_VCARDS_NO_MORE_SUCCESS';

export const ANEXAR_VCARD_START = 'ANEXAR_VCARD_START';
export const ANEXAR_VCARD_SUCCESS = 'ANEXAR_VCARD_SUCCESS';

export const DELETE_ANEXO_VCARD_START = 'DELETE_ANEXO_VCARD_START';
export const DELETE_ANEXO_VCARD_SUCCESS = 'DELETE_ANEXO_VCARD_SUCCESS';

export const ADD_VCARD_FAV_START = 'ADD_VCARD_FAV_START';
export const ADD_VCARD_FAV_SUCCESS = 'ADD_VCARD_FAV_SUCCESS';

export const SET_VCARD_STATUS_START = 'SET_VCARD_STATUS_START';
export const SET_VCARD_STATUS_SUCCESS = 'SET_VCARD_STATUS_SUCCESS';

export const SET_VCARDS_USER_START = 'SET_VCARDS_USER_START';
export const SET_VCARDS_USER_SUCCESS = 'SET_VCARDS_USER_SUCCESS';

export const SET_VCARD_IMAGE_START = 'SET_VCARD_IMAGE_START';
export const SET_VCARD_IMAGE_SUCCESS = 'SET_VCARD_IMAGE_SUCCESS';
export const SET_VCARD_IMAGE_FINISH = 'SET_VCARD_IMAGE_FINISH';

export const ADD_BI_VCARD_START = 'ADD_BI_VCARD_START';
export const ADD_BI_VCARD_SUCCESS = 'ADD_BI_VCARD_SUCCESS';
export const ADD_BI_VCARD_CLEAR = 'ADD_BI_VCARD_CLEAR'

export const SET_BI_VCARD_START = 'SET_BI_VCARD_START';
export const SET_BI_VCARD_SUCCESS = 'SET_BI_VCARD_SUCCESS';

export const SET_VCARD_FAIL = 'SET_VCARD_FAIL';
export const VCARD_CLEAN = 'VCARD_CLEAN';

//USER
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const SET_USERS_START = 'SET_USERS_START';
export const SET_USERS_SUCCESS = 'SET_USERS_SUCCESS';
export const SET_USERS_FAIL = 'SET_USERS_FAIL';

export const SET_USER_START = 'SET_USER_START';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAIL = 'SET_USER_FAIL';

export const ANEXAR_FOTO_USER_START = 'ANEXAR_FOTO_USER_START';
export const ANEXAR_FOTO_USER_SUCCESS = 'ANEXAR_FOTO_USER_SUCCESS';

export const DELETE_ANEXO_FOTO_USER_START = 'ANEXAR_FOTO_USER_START';
export const DELETE_ANEXO_FOTO_USER_SUCCESS = 'ANEXAR_FOTO_USER_SUCCESS';

export const SET_USER_IMAGE_START = 'SET_USER_IMAGE_START';
export const SET_USER_IMAGE_SUCCESS = 'SET_USER_IMAGE_SUCCESS';
export const SET_USER_IMAGE_FINISH = 'SET_USER_IMAGE_FINISH';

export const EXIT_USERS = 'EXIT_USERS';

//CATEGORIAS
export const DELETE_CATEGORIA_START = 'DELETE_CATEGORIA_START';
export const DELETE_CATEGORIA_SUCCESS = 'DELETE_CATEGORIA_SUCCESS';
export const DELETE_CATEGORIA_FAIL = 'DELETE_CATEGORIA_FAIL';

export const ADD_CATEGORIA_START = 'ADD_CATEGORIA_START';
export const ADD_CATEGORIA_SUCCESS = 'ADD_CATEGORIA_SUCCESS';
export const ADD_CATEGORIA_FAIL = 'ADD_CATEGORIA_FAIL';

export const SET_CATEGORIAS_START = 'SET_CATEGORIAS_START';
export const SET_CATEGORIAS_SUCCESS = 'SET_CATEGORIAS_SUCCESS';
export const SET_CATEGORIAS_FAIL = 'SET_CATEGORIAS_FAIL';

export const SET_SUB_CATEGORIAS_START = 'SET_SUB_CATEGORIAS_START';
export const SET_SUB_CATEGORIAS_SUCCESS = 'SET_SUB_CATEGORIAS_SUCCESS';
export const SET_SUB_CATEGORIAS_FAIL = 'SET_SUB_CATEGORIAS_FAIL';

export const SET_CATEGORIA_START = 'SET_CATEGORIA_START';
export const SET_CATEGORIA_SUCCESS = 'SET_CATEGORIA_SUCCESS';
export const SET_CATEGORIA_FAIL = 'SET_CATEGORIA_FAIL';

export const EXIT_CATEGORIAS = 'EXIT_CATEGORIAS';

//ESTADOS
export const SET_ESTADOS_START = 'SET_ESTADOS_START';
export const SET_ESTADOS_SUCCESS = 'SET_ESTADOS_SUCCESS';
export const SET_ESTADOS_FAIL = 'SET_ESTADOS_FAIL';
export const EXIT_ESTADOS = 'EXIT_ESTADOS';

//CONTATO
export const ADD_CONTATO_START = 'ADD_CONTATO_START';
export const ADD_CONTATO_SUCCESS = 'ADD_CONTATO_SUCCESS';
export const ADD_CONTATO_FAIL = 'ADD_CONTATO_FAIL';

export const SET_CONTATO_START = 'SET_CONTATO_START';
export const SET_CONTATO_SUCCESS = 'SET_CONTATO_SUCCESS';
export const SET_CONTATO_FAIL = 'SET_CONTATO_FAIL';

export const EXIT_CONTATO = 'EXIT_CONTATO';

//CHAT
export const ADD_CHAT_START = 'ADD_CHAT_START';
export const ADD_CHAT_SUCCESS = 'ADD_CHAT_SUCCESS';

export const SET_CHATS_START = 'SET_CHATS_START';
export const SET_CHATS_SUCCESS = 'SET_CHATS_SUCCESS';

export const SET_CHAT_START = 'SET_CHAT_START';
export const SET_CHAT_SUCCESS = 'SET_CHAT_SUCCESS';
export const SET_CHAT_FAIL = 'SET_CHAT_FAIL';

export const SET_CHAT_MSG_START = 'SET_CHAT_MSG_START';
export const SET_CHAT_MSG_SUCCESS = 'SET_CHAT_MSG_SUCCESS';
export const SET_CHAT_MSG_FAIL = 'SET_CHAT_MSG_FAIL';

export const ADD_CHAT_VIDEO_START = 'ADD_CHAT_VIDEO_START';
export const ADD_CHAT_VIDEO_SUCCESS = 'ADD_CHAT_VIDEO_SUCCESS';
export const ADD_CHAT_VIDEO_FAIL = 'ADD_CHAT_VIDEO_FAIL';

export const CLEAN_CHAT_VIDEO_START = 'CLEAN_CHAT_VIDEO_START';
export const CLEAN_CHAT_VIDEO_SUCCESS = 'CLEAN_CHAT_VIDEO_SUCCESS';
export const CLEAN_CHAT_VIDEO_FAIL = 'CLEAN_CHAT_VIDEO_FAIL';

export const ADD_CHAT_ANEXO_START = 'ADD_CHAT_ANEXO_START';
export const ADD_CHAT_ANEXO_SUCCESS = 'ADD_CHAT_ANEXO_SUCCESS';

export const CHAT_CLEAN = 'CHAT_CLEAN';

//UNIDADE
export const DELETE_UNIDADE_START = 'DELETE_UNIDADE_START';
export const DELETE_UNIDADE_SUCCESS = 'DELETE_UNIDADE_SUCCESS';
export const DELETE_UNIDADE_FAIL = 'DELETE_UNIDADE_FAIL';

export const ADD_UNIDADE_START = 'ADD_UNIDADE_START';
export const ADD_UNIDADE_SUCCESS = 'ADD_UNIDADE_SUCCESS';
export const ADD_UNIDADE_FAIL = 'ADD_UNIDADE_FAIL';

export const SET_UNIDADES_START = 'SET_UNIDADES_START';
export const SET_UNIDADES_SUCCESS = 'SET_UNIDADES_SUCCESS';
export const SET_UNIDADES_FAIL = 'SET_UNIDADES_FAIL';

export const SET_UNIDADE_START = 'SET_UNIDADE_START';
export const SET_UNIDADE_SUCCESS = 'SET_UNIDADE_SUCCESS';
export const SET_UNIDADE_FAIL = 'SET_UNIDADE_FAIL';

export const EXIT_UNIDADES = 'EXIT_UNIDADES';

//FORNECEDOR
export const DELETE_FORNECEDOR_START = 'DELETE_FORNECEDOR_START';
export const DELETE_FORNECEDOR_SUCCESS = 'DELETE_FORNECEDOR_SUCCESS';
export const DELETE_FORNECEDOR_FAIL = 'DELETE_FORNECEDOR_FAIL';

export const ADD_FORNECEDOR_START = 'ADD_FORNECEDOR_START';
export const ADD_FORNECEDOR_SUCCESS = 'ADD_FORNECEDOR_SUCCESS';
export const ADD_FORNECEDOR_FAIL = 'ADD_FORNECEDOR_FAIL';

export const SET_FORNECEDORES_START = 'SET_FORNECEDORES_START';
export const SET_FORNECEDORES_SUCCESS = 'SET_FORNECEDORES_SUCCESS';
export const SET_FORNECEDORES_FAIL = 'SET_FORNECEDORES_FAIL';

export const SET_FORNECEDOR_START = 'SET_FORNECEDOR_START';
export const SET_FORNECEDOR_SUCCESS = 'SET_FORNECEDOR_SUCCESS';
export const SET_FORNECEDOR_FAIL = 'SET_FORNECEDOR_FAIL';

export const EXIT_FORNECEDORES = 'EXIT_FORNECEDORES';

//ACOES
export const DELETE_ACOES_START = 'DELETE_ACOES_START';
export const DELETE_ACOES_SUCCESS = 'DELETE_ACOES_SUCCESS';
export const DELETE_ACOES_FAIL = 'DELETE_ACOES_FAIL';

export const ADD_ACOES_START = 'ADD_ACOES_START';
export const ADD_ACOES_SUCCESS = 'ADD_ACOES_SUCCESS';
export const ADD_ACOES_FAIL = 'ADD_ACOES_FAIL';

export const SET_ACOES_START = 'SET_ACOES_START';
export const SET_ACOES_SUCCESS = 'SET_ACOES_SUCCESS';
export const SET_ACOES_FAIL = 'SET_ACOES_FAIL';

export const SET_OPCOES_START = 'SET_OPCOES_START';
export const SET_OPCOES_SUCCESS = 'SET_OPCOES_SUCCESS';
export const SET_OPCOES_FAIL = 'SET_OPCOES_FAIL';

export const EXIT_ACOES = 'EXIT_ACOES';

//FUNCOES
export const DELETE_FUNCAO_START = 'DELETE_FUNCAO_START';
export const DELETE_FUNCAO_SUCCESS = 'DELETE_FUNCAO_SUCCESS';
export const DELETE_FUNCAO_FAIL = 'DELETE_FUNCAO_FAIL';

export const ADD_FUNCAO_START = 'ADD_FUNCAO_START';
export const ADD_FUNCAO_SUCCESS = 'ADD_FUNCAO_SUCCESS';
export const ADD_FUNCAO_FAIL = 'ADD_FUNCAO_FAIL';

export const SET_FUNCOES_START = 'SET_FUNCOES_START';
export const SET_FUNCOES_SUCCESS = 'SET_FUNCOES_SUCCESS';
export const SET_FUNCOES_FAIL = 'SET_FUNCOES_FAIL';

export const SET_FUNCAO_START = 'SET_FUNCAO_START';
export const SET_FUNCAO_SUCCESS = 'SET_FUNCAO_SUCCESS';
export const SET_FUNCAO_FAIL = 'SET_FUNCAO_FAIL';

export const EXIT_FUNCOES = 'EXIT_FUNCOES';

//AUTH
export const NOTIFICATION_OPENSUCCESS = 'NOTIFICATION_OPENSUCCESS';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';