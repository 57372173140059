import * as actionTypes from './actionTypes';
import FireBase from '../../utils/firebase';

let unsubscribeContatos = () => {};

//ADICIONAR
export const addContatoStart = () => {
    return {
        type: actionTypes.ADD_CONTATO_START
    };
}

export const addContato = (contato) => {
    return dispatch => {

        contato = {...contato, ...{desativado: false, created: FireBase.timeStamp() }}
       
        dispatch(addContatoStart());
        FireBase.db.collection('contato')
                            .add(contato)
                            .then((response) => {
                                dispatch(addContatoSuccess());
                            })
                            .catch(error => {
                                dispatch(addContatoFail(error));
                            });
    }
}

export const addContatoSuccess = () => {
    return {
        type: actionTypes.ADD_CONTATO_SUCCESS,
        contato: [],
    };
};

export const addContatoFail = (error) => {
    return {
        type: actionTypes.ADD_CONTATO_FAIL,
        error: error.name
    };
};

// GET ALL
export const setContatosStart = () => {
    return {
        type: actionTypes.SET_CONTATO_START
    }
}

export const getContatos = (header = null, options = null, lidos = null) => {
    return dispatch =>{
        dispatch(setContatosStart());
        
        unsubscribeContatos = FireBase.db.collection('contato')
            .where('desativado', '==', false)
            .onSnapshot(querySnapshot => {
                let docAux = null;
                let lines = [];
                
               
                querySnapshot.forEach(function(doc) {
                
                    let optionsAux = options;
                    if(optionsAux){                    
                        optionsAux = optionsAux
                            .map(item=>{
                                return {
                                    ...item, 
                                    click: ()=>{item.click(doc.id)}
                                };
                            })
                            .reduce((arr, el) => {
                                return arr.concat(el);
                            }, []);
                    }
                    let d = doc.data();

                    if(header && options){
                        d.id = doc.id;
                        lines = lines.concat([{data: d, options: optionsAux}]);
                    }else{
                        lines = lines.concat([d]);
                    }
                });

                if(header && options){
                    docAux = {header: header};
                    docAux.lines = lines;
                }else{
                    docAux = lines;
                }
                dispatch(setContatosSuccess(docAux));
            });
    }  
}

export const setContatosSuccess = (contato) => {
    return {
        type: actionTypes.SET_CONTATO_SUCCESS,
        contato: contato
    }
}

export const setContatosFail = (error) => {
    return {
        type: actionTypes.SET_CONTATO_FAIL,
        error: error
    }
}

//CLEAN SUCCESS
export const contatoCleanSucces = () => {
    return dispatch => {
        dispatch(exitContatoSuccess());
    }
}

// EXIT CONTATO
export const exitContato = () => {
    return dispatch => {
        unsubscribeContatos();
        dispatch(exitContatoSuccess());
    }
}

export const exitContatoSuccess = () => {
    return {
        type: actionTypes.EXIT_CONTATO
    }
}